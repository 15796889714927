import { useTranslation } from 'next-i18next';

import { api } from '@api/index';
import {
  GetBalanceRo,
  GetBalanceRouteParams,
} from '@api/ocb-digital/subscription/types';
import { useApiQuery } from '@lib/fetch/useApiQuery';
import { useSubscriptions } from '@lib/subscriptions/SubscriptionsContext';
import { useTenantProps } from '@lib/tenants/TenantPropsContext';

import { RequestBalanceReturnType } from './types';
import { useAuth } from '@lib/auth/AuthContext';

export const useRequestBalance = (
  cacheKey: string | string[],
  enabled = false,
): RequestBalanceReturnType => {
  const { selectedSubscription } = useSubscriptions();
  const { isAuthenticated } = useAuth();
  const { t } = useTranslation();
  const { tenant } = useTenantProps();
  const query = useApiQuery(requestBalance, {
    enabled,
    queryKey: [cacheKey, selectedSubscription?.id],
  });

  return query;

  async function requestBalance(): Promise<GetBalanceRo | undefined> {
    if (!selectedSubscription) {
      if (isAuthenticated) {
        // eslint-disable-next-line no-console
        console.error('User not authenticated');
        throw new Error(t('common:errors.unexpected'));
      }
      return;
    }

    try {
      const routeParams: GetBalanceRouteParams = {
        id: selectedSubscription.id,
      };
      const res = await api.ocbDigital.subscription.getBalance(
        routeParams,
        tenant,
      );

      return res;
    } catch {
      throw new Error(t('common:notifications.balanceTimeout'));
    }
  }
};
