import { FC } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'next-i18next';

import { api } from '@api/index';
import { PostTicketDto } from '@api/ocb-digital/ticket/types';
import { GetTicketCategoryRo } from '@api/ocb-digital/ticket-category/types';
import { CACHE_KEYS } from '@lib/fetch/constants';
import { useApiMutation } from '@lib/fetch/useApiMutation';
import { useApiQuery } from '@lib/fetch/useApiQuery';
import { useSubscriptions } from '@lib/subscriptions/SubscriptionsContext';
import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import { useClassifiers } from '@lib/useClassifiers';
import { CenteredLoader } from '@ui/loaders/CenteredLoader';
import { NoDataPlaceholder } from '@ui/placeholders/NoDataPlaceholder';

import { SupportForm } from './SupportForm';
import { SupportSubmitMutationDto } from './types';

interface Props {
  onSubmit?: () => void;
}

export const SupportCardContent: FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const { getClassifier, isLoading: isLoadingClassifiers } = useClassifiers();
  const communicationMethod = getClassifier('communicationMethod');
  const formSubmitCb = useApiMutation(handleSubmitRequest);
  const {
    selectedSubscription,
    isLoading: subscriptionsLoading,
    isFetching: subscriptionsFetching,
  } = useSubscriptions();
  const {
    data: categoriesSubcategories,
    isLoading: isLoadingCategoresSubcategories,
  } = useApiQuery(handleCategoryRequest, {
    queryKey: CACHE_KEYS.ticketCategory(selectedSubscription?.id ?? ''),
  });
  const { tenant } = useTenantProps();

  if (subscriptionsLoading || subscriptionsFetching) {
    return <CenteredLoader />;
  }

  if (
    !selectedSubscription &&
    !subscriptionsLoading &&
    !subscriptionsFetching
  ) {
    return <NoDataPlaceholder title={t('common:errors.noActivePackages')} />;
  }

  return (
    <SupportForm
      formSubmitCb={formSubmitCb}
      communicationMethod={communicationMethod}
      categoriesSubcategories={categoriesSubcategories}
      isLoadingCategoresSubcategories={isLoadingCategoresSubcategories}
      isLoadingClassifiers={isLoadingClassifiers}
    />
  );

  async function handleSubmitRequest({
    formValues,
    resetForm,
  }: SupportSubmitMutationDto): Promise<void> {
    if (!selectedSubscription?.id) {
      return;
    }

    try {
      const dto: PostTicketDto = {
        subscriptionId: selectedSubscription.id,
        category: formValues.category,
        subCategory: formValues.subcategory,
        description: formValues.description,
        communicationMethod: formValues.communicationMethod,
      };

      const postTicketResponse = await api.ocbDigital.ticket.postTicket(
        dto,
        tenant,
      );
      toast.success(
        t('home:auth.tickets.supportModal.form.notifications.success-with-id', {
          id: postTicketResponse.identifier,
        }),
        { autoClose: false, closeOnClick: false },
      );
      resetForm();
      onSubmit && onSubmit();
    } catch (err) {
      throw err;
    }
  }

  async function handleCategoryRequest(): Promise<
    GetTicketCategoryRo | undefined
  > {
    try {
      const data =
        await api.ocbDigital.ticketCategory.getTicketCategory(tenant);

      return data;
    } catch (err) {
      throw err;
    }
  }
};
