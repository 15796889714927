import { object, string } from 'yup';

import { DESCRIPTION_LIMIT } from './constants';

const shape = {
  category: string().required('common:validation.fieldRequired'),
  subcategory: string().required('common:validation.fieldRequired'),
  description: string()
    .required('common:validation.fieldRequired')
    .max(DESCRIPTION_LIMIT, ({ max }) => ({
      key: 'common:validation.string.max',
      values: { count: max },
    })),
  communicationMethod: string().required('common:validation.fieldRequired'),
};

export const schema = object<typeof shape>().shape(shape);
