import { FC } from 'react';

import { useRequestBalance } from '@lib/balance/useRequestBalance';
import { CACHE_KEYS } from '@lib/fetch/constants';
import { Stack } from '@mui/material';
import { CenteredLoader } from '@ui/loaders/CenteredLoader';

import { AmountField } from './AmountField';
import { ErrorPlaceholder } from '@ui/placeholders/ErrorPlaceholder';
import { useTranslation } from 'next-i18next';

interface Props {
  productId: string;
}

export const AddonBalance: FC<Props> = ({ productId }) => {
  const { t } = useTranslation();
  const { data, isLoading, isFetching, isError } = useRequestBalance(
    CACHE_KEYS.addonsBalance,
  );
  const balances =
    data?.balances?.filter(
      (balance) => balance.balanceSource.productId === productId,
    ) ?? [];

  if (isLoading || isFetching) {
    return <CenteredLoader />;
  }

  if (!balances.length) {
    return <ErrorPlaceholder title={t('common:errors.noBalanceData')} />;
  }

  if (isError) {
    return <ErrorPlaceholder />;
  }

  return (
    <Stack spacing={2}>
      {balances.map((balance, key) => (
        <Stack key={key} spacing={1}>
          <AmountField
            translationKey="home:auth.addons.remainingBalance"
            price={balance?.outstandingAmount}
            unitType={balance?.unitType}
          />
          <AmountField
            translationKey="home:auth.addons.initialBalance"
            price={balance?.initialAmount}
            unitType={balance?.unitType}
          />
        </Stack>
      ))}
    </Stack>
  );
};
