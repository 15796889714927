import { FC, useEffect } from 'react';
import { Trans } from 'next-i18next';

import { api } from '@api/index';
import { CACHE_KEYS } from '@lib/fetch/constants';
import { useApiQuery } from '@lib/fetch/useApiQuery';
import { APP_ROUTES } from '@lib/route/constants';
import { useSubscriptions } from '@lib/subscriptions/SubscriptionsContext';
import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import { CollapsableAlert } from '@ui/alerts/CollapsableAlert';
import { TextLink } from '@ui/buttons/TextLink';

export const ActivateSimCardsAlert: FC = () => {
  const { tenant } = useTenantProps();
  const { subscriptions } = useSubscriptions();
  const { data: pendingActivations, refetch } = useApiQuery(
    () => api.ocbDigital.sim.getSimStatus(tenant),
    { enabled: false, queryKey: [CACHE_KEYS.simStatus] },
  );
  const hasPendingSubscriptions = subscriptions.some(
    (sub) => sub.products[0].status === 'PENDING' || sub.status === 'PENDING',
  );

  useEffect(() => {
    if (hasPendingSubscriptions) {
      refetch();
    }
  }, [hasPendingSubscriptions, refetch]);

  if (pendingActivations && pendingActivations?.length > 0) {
    return (
      <CollapsableAlert severity="info">
        <Trans i18nKey="home:auth.activateSims">
          <TextLink href={APP_ROUTES.SIM.ACTIVATE.path} display="inline-flex" />
        </Trans>
      </CollapsableAlert>
    );
  }

  return null;
};
