import { SYMBOLS } from '@lib/placeholders/constants';
import { OrderTableItemSubscription } from './types';

export const formatSubscriptionsCell = (
  translateStatus: (status: string) => string,
  subscriptions?: OrderTableItemSubscription[],
): string => {
  if (!subscriptions || subscriptions.length === 0) {
    return SYMBOLS.emptyText;
  }

  const firstSubscription = subscriptions[0];
  const displayName =
    firstSubscription.identifier || firstSubscription.subscriptionId;
  const formattedFirstSubscription = firstSubscription.orderItemStatus
    ? `${displayName} (${translateStatus(firstSubscription.orderItemStatus)})`
    : displayName;

  if (subscriptions.length > 1) {
    return `${formattedFirstSubscription}, ...`;
  }

  return formattedFirstSubscription!;
};
