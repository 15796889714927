import { FC } from 'react';
import { Trans } from 'next-i18next';

import { Box } from '@mui/system';
import { PriceFormat } from '@ui/price/PriceFormat';

interface Props {
  translationKey: string;
  price?: number;
  unitType?: string;
}

export const AmountField: FC<Props> = ({ translationKey, price, unitType }) => {
  return (
    <Box display="flex" whiteSpace="pre-wrap">
      <Trans i18nKey={translationKey}>
        <PriceFormat price={price} />
        {` ${unitType}`}
      </Trans>
    </Box>
  );
};
