import { api } from '@api/index';
import { TenantResult } from '@api/ocb-digital/tenant/types';
import { getEnv } from '@lib/env/getEnv';
import { useApiQuery } from '@lib/fetch/useApiQuery';
import { useTenantProps } from '@lib/tenants/TenantPropsContext';

import { RequestTenantByIdReturnType } from './types';

export const useTenantById = (
  cacheKey: string | string[],
): RequestTenantByIdReturnType => {
  const { tenant } = useTenantProps();
  const query = useApiQuery(requestTenantById, { queryKey: [cacheKey] });

  return query;

  async function requestTenantById(): Promise<TenantResult | undefined> {
    try {
      const env = getEnv();
      const tenantSetupId = tenant?.config.setup.id[env.client.appEnv] ?? '';
      const tenantById = await api.ocbDigital.tenant.getTenantById(
        tenantSetupId,
        tenant,
      );

      return tenantById;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  }
};
