import { FC } from 'react';

import { SYMBOLS } from '@lib/placeholders/constants';
import { SxStyles } from '@lib/theme/sxTheme';
import { Box, InputLabel, InputLabelProps, Typography } from '@mui/material';

interface Props {
  value?: string;
  label?: string;
  inputLabelProps?: InputLabelProps;
}

export const PlainText: FC<Props> = ({
  label,
  value,
  inputLabelProps = {},
}) => {
  return (
    <Box>
      {label && (
        <InputLabel sx={styles.getValue('label')} {...inputLabelProps}>
          {label}:
        </InputLabel>
      )}
      <Typography
        whiteSpace="pre-wrap"
        title={value}
        sx={styles.getValue('text')}
      >
        {value ?? SYMBOLS.emptyText}
      </Typography>
    </Box>
  );
};

const styles = new SxStyles({
  label: {
    fontWeight: 'fontWeightBold',
  },
  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});
