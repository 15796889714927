import { FC } from 'react';

import { Stack } from '@mui/material';
import { CenteredLoader } from '@ui/loaders/CenteredLoader';
import { ErrorPlaceholder } from '@ui/placeholders/ErrorPlaceholder';

interface Props {
  isError: boolean;
  loaderLabel: string;
}

export const PaymentStepContent: FC<Props> = ({ isError, loaderLabel }) => {
  if (isError) {
    return <ErrorPlaceholder />;
  }

  return (
    <Stack justifyContent="center" textAlign="center" spacing={1}>
      <CenteredLoader title={loaderLabel} />
    </Stack>
  );
};
