import { FC, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'next-i18next';

import { GetTicketCategoryRo } from '@api/ocb-digital/ticket-category/types';
import { SelectOption } from '@lib/form/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { Button } from '@ui/buttons/Button';
import { Select } from '@ui/form/select/Select';
import { TextInput } from '@ui/form/text-input/TextInput';

import { DEFAULT_SUPPORT_FORM_VALUES, DESCRIPTION_LIMIT } from './constants';
import { schema } from './schema';
import { FormSubmitCb, SupportFormValues } from './types';

interface Props {
  formSubmitCb: FormSubmitCb;
  communicationMethod: SelectOption[];
  categoriesSubcategories: GetTicketCategoryRo | undefined;
  isLoadingCategoresSubcategories: boolean;
  isLoadingClassifiers: boolean;
}

export const SupportForm: FC<Props> = ({
  formSubmitCb,
  communicationMethod,
  isLoadingCategoresSubcategories,
  isLoadingClassifiers,
  categoriesSubcategories,
}) => {
  const { t } = useTranslation();
  const formMethods = useForm<SupportFormValues>({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_SUPPORT_FORM_VALUES,
  });
  const selectedCategory = formMethods.getValues('category');
  const categories = useMemo(
    () => mapCategories(categoriesSubcategories),
    [categoriesSubcategories]
  );
  const subcategories = useMemo(
    () => mapSubcategories(categoriesSubcategories, selectedCategory),
    [selectedCategory, categoriesSubcategories]
  );

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <Select
            options={categories}
            control={formMethods.control}
            name="category"
            label={t('home:auth.tickets.supportModal.form.label.category')}
            isLoading={isLoadingCategoresSubcategories}
          />
          <Select
            options={subcategories}
            control={formMethods.control}
            name="subcategory"
            label={t('home:auth.tickets.supportModal.form.label.subcategory')}
            isLoading={isLoadingCategoresSubcategories}
          />
          <Select
            options={communicationMethod}
            control={formMethods.control}
            name="communicationMethod"
            label={t(
              'home:auth.tickets.supportModal.form.label.communicationMethod'
            )}
            isLoading={isLoadingClassifiers}
          />
          <TextInput
            name="description"
            control={formMethods.control}
            label={t('home:auth.tickets.supportModal.form.label.description')}
            inputProps={{ maxLength: DESCRIPTION_LIMIT }}
            multiline
            minRows={4}
            counterLimit={DESCRIPTION_LIMIT}
          />
          <Button
            type="submit"
            loading={formSubmitCb.isPending}
            fullWidth
            data-testid="submit"
          >
            {t('home:auth.tickets.supportModal.form.button.submit')}
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );

  function onSubmit(formValues: SupportFormValues) {
    formSubmitCb.mutateAsync({
      formValues,
      resetForm: formMethods.reset,
    });
  }
};

function mapCategories(
  categoriesSubcategories: GetTicketCategoryRo | undefined
): SelectOption[] {
  return (
    categoriesSubcategories?.map((category) => ({
      id: category.id,
      label: category.name,
    })) ?? []
  );
}

function mapSubcategories(
  categoriesSubcategories: GetTicketCategoryRo | undefined,
  selectedCategory: string
): SelectOption[] {
  const categorySubcategories = categoriesSubcategories?.find(
    (category) => category.id === selectedCategory
  );

  if (categorySubcategories) {
    return categorySubcategories.subCategories.map((subcategory) => ({
      id: subcategory.id,
      label: subcategory.name,
    }));
  }

  return [];
}
