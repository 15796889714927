import { ContentModal } from '@ui/content-modal/ContentModal';
import { useTranslation } from 'next-i18next';
import { Elements } from '@stripe/react-stripe-js';
import { CheckoutForm } from './CheckoutForm';
import { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { getEnv } from '@lib/env/getEnv';
import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import { ErrorPlaceholder } from '@ui/placeholders/ErrorPlaceholder';

interface Props {
  onClose: () => void;
  open: boolean;
  clientSessionId?: string;
  totalUpfront: string | number;
  currency: string;
  callbackUrl: string;
}

let stripePromise: Promise<Stripe | null>;

export const StripePaymentModal: React.FC<Props> = ({
  onClose,
  open,
  clientSessionId,
  totalUpfront,
  currency,
  callbackUrl,
}) => {
  const { t } = useTranslation('common');
  const env = getEnv();
  const { tenant } = useTenantProps();
  const stripePublicKey =
    tenant.config.setup?.stripePublicKey?.[env.client.appEnv];

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (open && clientSessionId) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [open, clientSessionId]);

  if (!stripePromise && stripePublicKey) {
    stripePromise = loadStripe(stripePublicKey);
  }

  return (
    <ContentModal
      titleLabel={t('stripe-payment-modal.title')}
      isOpen={open}
      onCancel={onClose}
      minWidth="40vw"
      showCloseModalIcon
    >
      {!stripePublicKey ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <ErrorPlaceholder title={t('errors.unexpected')} />
        </Box>
      ) : loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Elements
          stripe={stripePromise}
          options={{ clientSecret: clientSessionId }}
        >
          <CheckoutForm
            callbackUrl={callbackUrl}
            totalUpfront={totalUpfront}
            currency={currency}
            onClose={onClose}
          />
        </Elements>
      )}
    </ContentModal>
  );
};
