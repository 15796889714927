import { FC } from 'react';

import { api } from '@api/index';
import { CACHE_KEYS } from '@lib/fetch/constants';
import { useApiQuery } from '@lib/fetch/useApiQuery';
import { useSubscriptions } from '@lib/subscriptions/SubscriptionsContext';
import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import { Box, Grid, Theme, useTheme } from '@mui/material';
import { ContentSpacer } from '@templates/common/layout/ContentSpacer';

import { InvoiceCard } from './invoice-card/InvoiceCard';
import { SubscriptionAddons } from './subscription-addons/SubscriptionAddons';
import { SubscriptionMainProduct } from './subscription-main-product/SubscriptionMainProduct';
import { Tickets } from './tickets/Tickets';
import { UserCard } from './user-card/UserCard';
import { UserProfileMenu } from './UserProfileMenu';
import { GradientBannerSpecifics, Tenant } from '@lib/tenants/types';
import { SxStyles } from '@lib/theme/sxTheme';
import { Resources } from './resources/Resources';
import { formatGenericError } from '@lib/fetch/errors';
import { toast } from 'react-toastify';
import { Payments } from './payments/Payments';
import { Orders } from './orders/Orders';
import { PurchaseInfoProvider } from '@templates/purchase/common/PurchaseInfoContext';

export const HomeAuth: FC = () => {
  const { tenant } = useTenantProps();
  const theme = useTheme();
  const specificBannerColors = tenant.theme?.specifics?.authUserGradientBanner;
  const { selectedSubscription } = useSubscriptions();
  const { data: customer, isLoading: isCustomerLoading } = useApiQuery(
    () => getCustomer(tenant),
    {
      queryKey: [CACHE_KEYS.personalDetails],
    },
  );

  const showInvoiceCard = selectedSubscription?.products?.some(
    (product) => product?.subscriptionType === 'POSTPAID',
  );

  const dynamicStyles = styles(theme, specificBannerColors);
  const accountId = customer?.accounts?.[0]?.id ?? '';

  return (
    <PurchaseInfoProvider>
      <ContentSpacer isPadded>
        <Box sx={dynamicStyles.getValue('banner')} />
        <Grid
          container
          spacing={4}
          sx={{ position: 'relative' }}
          data-testid="home-auth"
        >
          <Grid item xs={8} lg={4}>
            <UserCard />
          </Grid>
          <Grid item xs={4} lg={8}>
            <Box display="flex" justifyContent="flex-end">
              <UserProfileMenu />
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <SubscriptionMainProduct />
          </Grid>
          <Grid item xs={12} lg={4}>
            {showInvoiceCard && (
              <InvoiceCard
                accountId={accountId}
                isLoading={isCustomerLoading}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <SubscriptionAddons />
          </Grid>
          <Grid item xs={12}>
            <Resources />
          </Grid>
          <Grid item xs={12}>
            <Orders />
          </Grid>
          <Grid item xs={12}>
            <Payments accountId={accountId} />
          </Grid>
          <Grid item xs={12}>
            <Tickets />
          </Grid>
        </Grid>
      </ContentSpacer>
    </PurchaseInfoProvider>
  );

  async function getCustomer(tenant: Tenant) {
    try {
      const res = await api.ocbDigital.customer.getCustomer(tenant);
      return res;
    } catch (error) {
      toast.error(formatGenericError(error));
    }
  }
};

const styles = (
  theme: Theme,
  specificBannerColors?: GradientBannerSpecifics,
) => {
  return new SxStyles({
    banner: {
      width: '100%',
      height: '20vh',
      border: '1px solid #E6E6E6',
      background: `linear-gradient(180deg,
      ${
        specificBannerColors?.firstGradientColor || theme.palette.primary.dark
      } 0%, ${
        specificBannerColors?.secondGradientColor || theme.palette.primary.main
      } 100%)`,
      position: 'absolute',
      top: 0,
      left: 0,
    },
  });
};
