import { FC } from 'react';

import { SxStyles } from '@lib/theme/sxTheme';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { ARIA_TABLE_ID } from './constants';

interface Props {
  tableName: string;
}

export const TableToolbar: FC<Props> = (props) => {
  const { tableName } = props;

  return (
    <Toolbar sx={styles.getValue('toolbar')}>
      <Typography
        sx={styles.getValue('title')}
        variant="h6"
        id={ARIA_TABLE_ID}
        component="div"
      >
        {tableName}
      </Typography>
    </Toolbar>
  );
};

const styles = new SxStyles({
  toolbar: {
    pl: { sm: 2 },
    pr: { xs: 1, sm: 1 },
  },
  title: {
    flex: '1 1 100%',
  },
});
