import {
  MutationFunction,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';

import { handleErrors } from './errors';

export const useApiMutation = <
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
>(
  mutationFn: MutationFunction<TData, TVariables>,
  options?: UseMutationOptions<TData, TError, TVariables, TContext>
): UseMutationResult<TData, TError, TVariables, TContext> => {
  const { t } = useTranslation();

  return useMutation<TData, TError, TVariables, TContext>({
    ...options,
    mutationFn: (...a) => handleErrors(() => mutationFn(...a), t),
  });
};
