import { FC } from 'react';

import { api } from '@api/index';
import { CACHE_KEYS } from '@lib/fetch/constants';
import { useApiQuery } from '@lib/fetch/useApiQuery';
import { SYMBOLS } from '@lib/placeholders/constants';
import { useTenantProps } from '@lib/tenants/TenantPropsContext';

import { UserCardTemplate } from './UserCardTemplate';
import { Tenant } from '@lib/tenants/types';
import { toast } from 'react-toastify';
import { formatGenericError } from '@lib/fetch/errors';

export const UserCard: FC = () => {
  const { tenant } = useTenantProps();
  const { data: personalDetails, isLoading } = useApiQuery(
    () => getCustomer(tenant),
    { queryKey: [CACHE_KEYS.personalDetails] },
  );

  if (isLoading || !personalDetails) {
    return (
      <UserCardTemplate
        firstName={SYMBOLS.emptyText}
        lastName={SYMBOLS.emptyText}
      />
    );
  }

  const { firstName, lastName, companyName } = personalDetails;

  return (
    <UserCardTemplate
      firstName={firstName}
      lastName={lastName}
      companyName={companyName}
    />
  );

  async function getCustomer(tenant: Tenant) {
    try {
      const res = await api.ocbDigital.customer.getCustomer(tenant);
      return res;
    } catch (error) {
      toast.error(formatGenericError(error));
    }
  }
};
