import { FC } from 'react';
import { useTranslation } from 'next-i18next';

import { PriceSchedule, ProductFee } from '@lib/offers/types';
import { Box, Typography } from '@mui/material';
import { Stack } from '@mui/system';

import { PriceFormat } from './PriceFormat';
import { SxStyles } from '@lib/theme/sxTheme';
import { SxTheme } from '@lib/theme/types';
import { PriceDisplayType } from './types';

interface Props {
  priceSchedule: PriceSchedule[];
  recurringFee?: ProductFee;
  display?: PriceDisplayType;
  sx?: SxTheme;
}

export const ScheduledPrice: FC<Props> = ({
  priceSchedule,
  recurringFee,
  display = 'productCard',
  sx,
}) => {
  const { t } = useTranslation();
  const dynamicStyles = styles({ display });
  const pricingStyles =
    display === 'productCard'
      ? dynamicStyles.getValue('pricingFont')
      : dynamicStyles.merge('pricingFont', sx);

  return (
    <Stack>
      {getScheduledPrices(priceSchedule)?.map((scheduledPrice, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            gap: 1,
            justifyContent: 'inherit',
          }}
        >
          <PriceFormat
            price={scheduledPrice.price.price}
            currency={scheduledPrice.price.currency}
            sx={pricingStyles}
          />
          <Typography
            sx={pricingStyles}
          >{`(${scheduledPrice.startMonth}-${scheduledPrice.endMonth} ${t('common:prepaidPackages.month')})`}</Typography>
        </Box>
      ))}
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          gap: 1,
        }}
      >
        <PriceFormat
          price={recurringFee?.price}
          currency={recurringFee?.currency}
          sx={pricingStyles}
        />
        <Typography sx={pricingStyles}>
          {t('common:prices.forRestOfPeriod')}
        </Typography>
      </Box>
    </Stack>
  );

  function getScheduledPrices(scheduledPrices: PriceSchedule[]) {
    return scheduledPrices.map((schedule, index) => {
      const startMonth =
        index === 0 ? 1 : scheduledPrices[index - 1].duration + 1;
      const endMonth =
        index === 0 ? schedule.duration : startMonth + schedule.duration;

      return { price: schedule.price, startMonth, endMonth };
    });
  }
};

const styles = ({ display }: { display?: PriceDisplayType }) =>
  new SxStyles({
    pricingFont: { ...(display === 'productCard' && { fontWeight: 'bold' }) },
  });
