import { FC } from 'react';
import { useTranslation } from 'next-i18next';

import { getYupErrorMessage } from '@lib/form/errors';
import { YupError } from '@lib/form/types';
import { FORM_ERROR_TEST_ID } from '@lib/test/constants';
import { SxStyles } from '@lib/theme/sxTheme';
import { FormHelperText } from '@mui/material';

interface Props {
  error: false | YupError;
}

export const ErrorText: FC<Props> = ({ error }) => {
  const { t } = useTranslation();

  if (!!error) {
    return (
      <FormHelperText
        sx={styles.getValue('root')}
        data-testid={FORM_ERROR_TEST_ID}
      >
        {getYupErrorMessage(error, t)}
      </FormHelperText>
    );
  }

  return null;
};

const styles = new SxStyles({
  root: {
    color: 'error.main',
  },
});
