import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'next-i18next';

import { api } from '@api/index';
import { FailureDetails } from '@api/ocb-digital/order/types';
import { useApiMutation } from '@lib/fetch/useApiMutation';
import { useTenantProps } from '@lib/tenants/TenantPropsContext';

import { DELAY_TIMER } from './constants';
import {
  PaymentCheckerParams,
  PaymentCheckerReturnType,
  PaymentMutationParams,
  PaymentProviderType,
  SupportedPaymentTypes,
} from './types';
import { useAuth } from '@lib/auth/AuthContext';
import { useRouter } from 'next/router';
import { PurchaseQueryParams } from '@templates/purchase/common/types';

export const usePaymentChecker = ({
  onPaymentSuccess,
  onPaymentError,
  onPaymentSkip,
  onRedirect,
  onOpenPaymentModal,
  customOrderApi,
}: PaymentCheckerParams = {}): PaymentCheckerReturnType => {
  const router = useRouter();
  const { paymentSubmitted } = router.query as PurchaseQueryParams;
  const { isAuthenticated } = useAuth();
  const [isPaymentError, togglePaymentError] = useState(false);
  const [isPaymentLoading, togglePaymentLoading] = useState(false);
  const [paymentCheckTimes, setPaymentCheckTimes] = useState(0);
  const { t } = useTranslation();
  const { tenant } = useTenantProps();
  const isMounted = useRef(false);
  const {
    data,
    mutateAsync: fetchOrder,
    isError: isOrderError,
  } = useApiMutation(onCheckPayment);
  const [persistedData, persistData] = useState(data);

  useEffect(() => {
    if (data) {
      persistData(data);
    }
  }, [data]);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      DELAY_TIMER.stop();
      isMounted.current = false;
    };
  }, []);

  return {
    data: persistedData,
    isError: isOrderError || isPaymentError,
    isLoading: isPaymentLoading,
    fetchOrder,
  };

  async function onCheckPayment(
    params: PaymentMutationParams,
    maxIterations = 18,
  ) {
    try {
      togglePaymentLoading(true);
      if (paymentCheckTimes >= maxIterations) {
        togglePaymentError(true);
        togglePaymentLoading(false);
        return;
      }
      const order = customOrderApi
        ? await customOrderApi({ tenant, orderId: params.orderId })
        : isAuthenticated
          ? await api.ocbDigital.order.getOrder(
              { orderId: params.orderId },
              tenant,
            )
          : await api.ocbDigital.orderAuthless.getOrder(
              { orderId: params.orderId, requestId: params.requestId },
              tenant,
            );

      if (!order || !isMounted.current) {
        return;
      }

      const { orderPayment, orderStatus, failureDetails } = order;
      const isFail =
        orderStatus === 'FAILED' || orderStatus === 'PAYMENT_FAILED';
      const isPaymentSuccessful =
        orderPayment?.paymentStatus === 'FINISHED' &&
        orderStatus !== 'FAILED' &&
        orderStatus !== 'PAYMENT';
      const isPaymentReady =
        orderStatus === 'PAYMENT' &&
        orderPayment?.paymentStatus === 'INITIALIZED';
      const isOnlinePayment =
        orderPayment?.paymentType === SupportedPaymentTypes.Online;
      const isPaystackProvider =
        orderPayment?.providerType === PaymentProviderType.Paystack;
      const isTLyncPayment =
        orderPayment?.paymentType === SupportedPaymentTypes.TLync;
      const hasRedirectUrl = !!orderPayment?.redirectUrl;
      const isStripePayment =
        orderPayment?.providerType === 'STRIPE' && !paymentSubmitted;
      const isRyftPayment =
        orderPayment?.providerType === 'RYFT' && !paymentSubmitted;
      const isPayPalPayment =
        orderPayment?.paymentType === 'PAYPAL' && !paymentSubmitted;
      const isRefetch =
        !orderPayment?.paymentStatus ||
        !orderStatus ||
        orderStatus === 'PAYMENT';

      const isProviderWithRedirect =
        ((isOnlinePayment && isPaystackProvider) || isTLyncPayment) &&
        hasRedirectUrl &&
        isPaymentReady;

      if (isFail) {
        togglePaymentLoading(false);
        togglePaymentError(true);
        defaultPaymentError(failureDetails);
        onPaymentError && onPaymentError(failureDetails);
      } else if (params.isSkipping && onPaymentSkip) {
        togglePaymentLoading(false);
        onPaymentSkip();
      } else if (isRyftPayment && isPaymentReady && onOpenPaymentModal) {
        togglePaymentLoading(false);
        onOpenPaymentModal('RYFT');
      } else if (isStripePayment && isPaymentReady && onOpenPaymentModal) {
        togglePaymentLoading(false);
        onOpenPaymentModal('STRIPE');
      } else if (isPayPalPayment && isPaymentReady && onOpenPaymentModal) {
        togglePaymentLoading(false);
        onOpenPaymentModal('PAYPAL');
      } else if (isProviderWithRedirect && onRedirect) {
        togglePaymentLoading(false);
        onRedirect(orderPayment.redirectUrl, params.orderId);
      } else if (isPaymentSuccessful && onPaymentSuccess) {
        togglePaymentLoading(false);
        onPaymentSuccess();
      } else if (isRefetch) {
        setPaymentCheckTimes(paymentCheckTimes + 1);
        DELAY_TIMER.promise().then(() => fetchOrder(params));
      }
      return order;
    } catch (error) {
      togglePaymentLoading(false);
      throw error;
    }
  }

  function defaultPaymentError(details?: FailureDetails): void {
    if (details?.reason === 'UNEXPECTED_ERROR') {
      return;
    }

    const errorMsg = details?.reason
      ? t(`backend:errors.${details.reason}`, {
          description: details.description || '',
        })
      : t('common:errors.unexpected');
    toast.error(errorMsg);
  }
};
