import React, { FC, ReactNode } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import { HeaderAction } from '../types';
import { DefaultCardHeaderActions } from './DefaultCardHeaderActions';

interface Props {
  title: string | undefined;
  titleColor: string;
  titleNote?: ReactNode;
  actions: HeaderAction[] | undefined;
  button?: React.ReactNode;
}

export const DefaultCardHeader: FC<Props> = ({
  title,
  titleColor,
  titleNote,
  actions,
  button,
}) => {
  return (
    <Grid container p={2} alignItems="center">
      <Grid
        item
        xs={actions || button ? 6 : 12}
        container
        justifyContent="space-between"
      >
        <Typography color={titleColor} fontSize={20} fontWeight={500}>
          {title}
        </Typography>
        {titleNote}
      </Grid>
      <DefaultCardHeaderActions actions={actions} />
      {button && (
        <Grid item xs={6}>
          <Box display="flex" justifyContent="flex-end">
            {button}
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
