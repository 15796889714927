import { SubscriptionProduct } from '@api/ocb-digital/subscription/types';
import { useSubscriptions } from '@lib/subscriptions/SubscriptionsContext';
import {
  isProductNotExpired,
  isStatusActivePendingOrSuspended,
  productActivatesInFuture,
} from '@templates/common/helpers';
import { useMemo } from 'react';

const isValidProduct = (product: SubscriptionProduct) =>
  isProductNotExpired(product) &&
  isStatusActivePendingOrSuspended(product.status);

export const useManageSubscriptions = () => {
  const { selectedSubscription } = useSubscriptions();
  const parentProduct = useMemo(() => {
    if (!selectedSubscription) return null;
    return selectedSubscription.products?.find(isValidProduct);
  }, [selectedSubscription]);
  const products = useMemo(() => {
    if (
      !isStatusActivePendingOrSuspended(selectedSubscription?.status) ||
      !parentProduct
    ) {
      return undefined;
    }
    return parentProduct.children?.filter(isValidProduct);
  }, [selectedSubscription, parentProduct]);
  const isManageButtonVisible =
    selectedSubscription?.status === 'ACTIVE' &&
    parentProduct?.status === 'ACTIVE';
  const isManageButtonEnabled = useMemo(() => {
    return products?.every(
      (product) =>
        product.status === 'ACTIVE' ||
        (product.status === 'PENDING' && productActivatesInFuture(product)),
    );
  }, [products]);

  return {
    parentProduct,
    products,
    isManageButtonVisible,
    isManageButtonEnabled,
  };
};
