import { useRouter } from 'next/router';

import { formatDateTz } from '@lib/time/date';
import { DEFAULT_LOCALE } from '@lib/route/constants';
import { getDateFnsLocale } from '@lib/time/locale';

export const useLocalisedDate = () => {
  const { locale = DEFAULT_LOCALE } = useRouter();
  const dateFnsLocale = getDateFnsLocale(locale);

  return {
    dateFnsLocale,
    getCurrentDate: (format: string = 'P') =>
      formatDateTz(new Date(), format, {
        locale: dateFnsLocale,
      }),
    formatDateTz: (utcDate: string | number | Date, format: string = 'P') =>
      formatDateTz(utcDate, format, {
        locale: dateFnsLocale,
      }),
    formatDateTimeTz: (
      utcDate: string | number | Date,
      format: string = 'Pp',
    ) =>
      formatDateTz(utcDate, format, {
        locale: dateFnsLocale,
      }),
  };
};
