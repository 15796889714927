interface Props {
  onCancel(): void;
}

export const CloseModalIcon: React.FC<Props> = ({ onCancel }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      onClick={onCancel}
      style={{ cursor: 'pointer' }}
    >
      <path
        d="M15.2022 0C15.2429 0.0111091 15.2873 0.0222182 15.3281 0.0296242C15.9798 0.188855 16.2242 0.981303 15.7724 1.47751C15.7428 1.51084 15.7095 1.54416 15.6761 1.57749C13.5617 3.69192 11.4473 5.80635 9.33285 7.91708C9.30693 7.943 9.27731 7.95781 9.24398 7.98003C9.28101 8.02076 9.29953 8.03928 9.32174 8.0615C11.4473 10.187 13.5691 12.3126 15.6984 14.4381C15.8798 14.6196 16.002 14.8306 16.002 15.0935C16.002 15.4713 15.8317 15.7564 15.4873 15.9119C15.1466 16.0674 14.817 16.0156 14.5393 15.7675C14.306 15.5601 14.0912 15.3305 13.8728 15.1084C11.9398 13.1754 10.0068 11.2424 8.07382 9.30942C8.05531 9.2909 8.04049 9.26498 8.02568 9.24276C8.01457 9.23906 7.99976 9.23165 7.98865 9.22795C7.97014 9.25758 7.95903 9.29461 7.93311 9.32053C5.80757 11.4461 3.68203 13.5716 1.56019 15.6971C1.37134 15.886 1.15656 16.0008 0.886241 15.9934C0.515938 15.9823 0.238211 15.8082 0.09009 15.4676C-0.0580312 15.1343 -0.00248571 14.8195 0.227102 14.5381C0.256726 14.5011 0.293757 14.4677 0.327084 14.4307C2.44522 12.3126 4.56705 10.1907 6.68519 8.07261C6.7074 8.05039 6.73703 8.03187 6.76295 8.01336C6.76295 8.00225 6.77036 7.98744 6.77406 7.97633C6.74443 7.95781 6.71111 7.943 6.68519 7.92078C4.55224 5.79154 2.4267 3.666 0.29746 1.53676C-0.0654372 1.17386 -0.0876554 0.651733 0.245617 0.285133C0.37152 0.140715 0.530751 0.0555454 0.719605 0.0148121C0.73812 0.0111091 0.756635 0.00370303 0.771447 0H1.00104C1.27876 0.0444364 1.47502 0.218479 1.66758 0.407333C3.75609 2.49955 5.8446 4.58805 7.93681 6.68027C7.95903 6.70248 7.98125 6.7247 8.01457 6.75433C8.0442 6.721 8.06642 6.69138 8.08863 6.66545C10.2068 4.54732 12.3286 2.42548 14.4504 0.307351C14.5689 0.188855 14.7022 0.0888727 14.8652 0.0444364C14.9207 0.0296242 14.98 0.0148121 15.0392 0.00370303C15.0948 0.00370303 15.1466 0.00370303 15.2022 0.00370303V0Z"
        fill="black"
      />
    </svg>
  );
};
