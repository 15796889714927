import { ReactElement, ReactNode, useCallback, useMemo, useState } from 'react';
import {
  Control,
  FieldPath,
  FieldValues,
  PathValue,
  useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'next-i18next';

import { getFormError } from '@lib/form/errors';
import { SelectOption } from '@lib/form/types';
import { SYMBOLS } from '@lib/placeholders/constants';
import { SxStyles } from '@lib/theme/sxTheme';
import { SxTheme } from '@lib/theme/types';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select as MaterialSelect,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { CenteredLoader } from '@ui/loaders/CenteredLoader';

import { ErrorText } from '../ErrorText';
import { SelectChip } from './SelectChip';
import { UpdateFormValue } from './types';

interface Props<
  T extends SelectOption,
  Multiple extends boolean | undefined,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
  options: ReadonlyArray<T>;
  label: string | ReactNode;
  name: TName;
  control: Control<TFieldValues>;
  multiple?: Multiple;
  showClearAll?: boolean;
  isLoading?: boolean;
  getOptionLabel?: (option: T) => string;
  shrink?: boolean;
  disabled?: boolean;
  sx?: SxTheme;
}

export const Select = <
  T extends SelectOption,
  Multiple extends boolean | undefined,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  name,
  options,
  disabled = false,
  multiple = false,
  showClearAll = true,
  getOptionLabel,
  isLoading,
  shrink,
  sx,
}: Props<T, Multiple, TFieldValues, TName>): ReactElement | null => {
  const { t } = useTranslation();
  const dynamicStyles = styles({ showClearAll, multiple });
  const [isOpen, toggleDropdown] = useState<boolean>(false);
  const [isClearAllVisible, toggleClearAllVisibility] =
    useState<boolean>(false);
  const formMethods = useFormContext();
  const { formState, watch, setValue: setFormValue, register } = formMethods;
  const { ref } = register(name);
  const error = getFormError(name, formState.errors);
  const selectedIds = watch(name) as string | string[];
  const setSelectedIds: UpdateFormValue = useCallback(
    (value, params = { shouldValidate: true, shouldDirty: true }) => {
      setFormValue(name, value as PathValue<FieldValues, TName>, params);
    },
    [name, setFormValue],
  );
  const isOutOfRange = useMemo(
    () =>
      !options.find((option) =>
        multiple ? selectedIds.includes(option.id) : selectedIds === option.id,
      ),
    [multiple, options, selectedIds],
  );

  return (
    <FormControl
      error={!!error}
      component="fieldset"
      variant="outlined"
      fullWidth={true}
      aria-labelledby={name}
      ref={ref}
      disabled={disabled}
    >
      <InputLabel
        id={name}
        shrink={checkShrink(shrink, isOpen, selectedIds, multiple)}
      >
        {label}
      </InputLabel>
      <MaterialSelect
        variant="outlined"
        sx={dynamicStyles.merge('selectContainer', sx)}
        label={label}
        labelId={name}
        multiple={multiple}
        onChange={handleChange}
        open={isOpen}
        onOpen={openDropdown}
        onClose={closeDropdown}
        IconComponent={ArrowDropDownIcon}
        defaultValue={selectedIds}
        renderValue={
          multiple
            ? (selectedIds: string | string[]) => (
                <Box component="div" display="flex" flexWrap="wrap">
                  {Array.isArray(selectedIds) &&
                    selectedIds.map((selectedId) => (
                      <Box p={0.3} key={selectedId}>
                        <SelectChip
                          id={selectedId}
                          label={
                            options.find((option) => option.id === selectedId)
                              ?.label ?? SYMBOLS.emptyText
                          }
                          selectedIds={selectedIds}
                          updateFormValue={setSelectedIds}
                        />
                      </Box>
                    ))}
                </Box>
              )
            : undefined
        }
        value={isOutOfRange ? (multiple ? [] : '') : selectedIds}
        MenuProps={{
          transitionDuration: 0,
        }}
        data-testid={name}
      >
        {isLoading && (
          <CenteredLoader
            containerSx={dynamicStyles.getValue('unselectable')}
          />
        )}
        {!isLoading && !options.length && (
          <Typography p={1} sx={dynamicStyles.getValue('unselectable')}>
            {t('common:errors.noData')}
          </Typography>
        )}
        {!isLoading &&
          options.length &&
          options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {getOptionLabel
                ? getOptionLabel(option)
                : defaultOptionLabelGetter(option)}
            </MenuItem>
          ))}
      </MaterialSelect>
      <ErrorText error={error} />
    </FormControl>
  );

  function openDropdown(): void {
    toggleDropdown(true);
  }

  function closeDropdown(): void {
    toggleDropdown(false);

    if (isClearAllVisible) {
      toggleClearAllVisibility(false);
    }
  }

  function handleChange(event: SelectChangeEvent<string | string[]>): void {
    const ids = event.target.value;

    setSelectedIds(ids);
  }
};

function defaultOptionLabelGetter<T extends SelectOption>(option: T): string {
  return option.label;
}

function checkShrink(
  shrink: boolean | undefined,
  isOpen: boolean,
  selectedIds: string | string[],
  multiple: boolean,
): boolean {
  if (shrink === undefined) {
    if (isOpen) {
      return true;
    }

    if (multiple && Array.isArray(selectedIds)) {
      return !!selectedIds.length;
    }

    if (!multiple && typeof selectedIds === 'string') {
      return selectedIds !== '';
    }
  }

  return !!shrink;
}

interface StyleProps {
  showClearAll: boolean;
  multiple: boolean;
}

const styles = ({ showClearAll, multiple }: StyleProps) =>
  new SxStyles({
    hiddenIcon: {
      display: 'none',
    },
    selectContainer: {
      [`& > .MuiSelect-select`]: {
        minHeight: multiple ? 32 : 'initial',
        pr: `${showClearAll ? 56 : 30}px !important`,
      },
    },
    unselectable: {
      pointerEvents: 'none',
    },
  });
