import { FC } from 'react';
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { PayPalButtonsComponentOptions } from '@paypal/paypal-js/types/components/buttons';
import { CenteredLoader } from '@ui/loaders/CenteredLoader';

const PAYPAL_BUTTON_STYLES: PayPalButtonsComponentOptions['style'] = {
  shape: 'rect',
  layout: 'vertical',
};

interface Props {
  payPalOrderId: string;
  onPaymentCancel: PayPalButtonsComponentOptions['onCancel'];
  onPaymentApprove: PayPalButtonsComponentOptions['onApprove'];
}

export const PayPalPaymentButton: FC<Props> = ({
  payPalOrderId,
  onPaymentCancel,
  onPaymentApprove,
}) => {
  const [{ isPending }] = usePayPalScriptReducer();

  if (isPending) return <CenteredLoader />;
  if (!payPalOrderId) return null;

  return (
    <PayPalButtons
      style={PAYPAL_BUTTON_STYLES}
      onCancel={onPaymentCancel}
      onApprove={onPaymentApprove}
      createOrder={handleCreateOrder}
    />
  );

  async function handleCreateOrder() {
    return payPalOrderId as string;
  }
};
