import { TFunction } from 'next-i18next';

import { SubscriptionProductChild } from '@api/ocb-digital/subscription/types';
import { OfferCardLine } from '@templates/common/offer-card/types';
import { OneTimeFee } from '@ui/price/OneTimeFee';
import { RecurringFee } from '@ui/price/RecurringFee';
import { InfoTooltip } from '@ui/tooltip/InfoTooltip';
import { Stack, Typography } from '@mui/material';
import { productActivatesTodayOrInFuture } from '@templates/common/helpers';

export const mapToOfferCardLines = (
  product: SubscriptionProductChild | undefined,
  isBasePackage: boolean,
  t: TFunction,
  formatDateTimeTz: (dateTimeISO: string | number | Date) => string,
  shouldShowFuturePendingTooltip?: boolean,
): OfferCardLine[] => {
  const lines: OfferCardLine[] = [];

  if (!product) {
    return lines;
  }

  // Base package uses contract start/end dates, add-ons valid from/till
  if (isBasePackage) {
    if (product.contractStartDate) {
      lines.push({
        value: formatDateTimeTz(product.contractStartDate),
        label: t('home:auth.contractStartDate'),
      });
    }

    if (product.contractEndDate) {
      lines.push({
        value: formatDateTimeTz(product.contractEndDate),
        label: t('home:auth.contractEndDate'),
      });
    }
  } else {
    if (product.validFrom) {
      lines.push({
        value: formatDateTimeTz(product.validFrom),
        label: t('home:auth.validFrom'),
      });
    }

    if (product.validTill) {
      lines.push({
        value: formatDateTimeTz(product.validTill),
        label: t('home:auth.validTo'),
      });
    }
  }

  if (product.productPricing) {
    lines.push(
      {
        value: (
          <OneTimeFee
            pricing={product.productPricing}
            display="normal"
            fontSize="16px"
          />
        ),
        label: t('common:oneTimeFee'),
      },
      {
        value: (
          <RecurringFee
            pricing={product.productPricing}
            display="normal"
            fontSize="16px"
          />
        ),
        label: t('common:recurringFee'),
      },
    );
  }
  if (product.status) {
    lines.push({
      value: formatProductStatus(product, t, shouldShowFuturePendingTooltip),
      label: t('home:auth.addons.status'),
    });
  }

  return lines;
};

function formatProductStatus(
  product: SubscriptionProductChild,
  t: TFunction,
  shouldShowFuturePendingTooltip?: boolean,
) {
  if (
    shouldShowFuturePendingTooltip &&
    product.status === 'PENDING' &&
    productActivatesTodayOrInFuture(product)
  ) {
    return (
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography fontSize="16px" component="div">
          {product.status}
        </Typography>
        <InfoTooltip title={t('home:auth.addons.tooltip.futurePending')} />
      </Stack>
    );
  }

  return product.status;
}
