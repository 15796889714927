import { FC } from 'react';
import { useTranslation } from 'next-i18next';

import { SxStyles } from '@lib/theme/sxTheme';
import SearchOffOutlinedIcon from '@mui/icons-material/SearchOffOutlined';

import { GenericPlaceholder } from './GenericPlaceholder';

interface Props {
  title?: string;
}

export const NoDataPlaceholder: FC<Props> = (props) => {
  const { title } = props;
  const { t } = useTranslation();

  return (
    <GenericPlaceholder
      icon={<SearchOffOutlinedIcon sx={styles.getValue('icon')} />}
      title={title ?? t('common:errors.noData')}
    />
  );
};

const styles = new SxStyles({
  icon: {
    fontSize: 50,
  },
});
