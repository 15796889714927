import { FC } from 'react';

import { SxStyles } from '@lib/theme/sxTheme';
import { Tooltip, TooltipProps } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { TextColor } from '@lib/slice-machine/types';

interface Props extends Omit<TooltipProps, 'children'> {
  color?: TextColor;
}

export const InfoTooltip: FC<Props> = ({
  title,
  color = 'primary.main',
  placement = 'right',
}) => {
  const dynamicStyles = styles({
    color,
  });

  if (!title) {
    return null;
  }

  return (
    <Tooltip
      title={title}
      placement={placement}
      arrow
      slotProps={{
        tooltip: { sx: dynamicStyles.getValue('tooltip') },
        arrow: { sx: dynamicStyles.getValue('arrow') },
      }}
    >
      <InfoIcon sx={dynamicStyles.getValue('icon')} />
    </Tooltip>
  );
};

interface StyleProps {
  color: TextColor;
}

const styles = ({ color }: StyleProps) => {
  return new SxStyles({
    tooltip: {
      backgroundColor: color,
      fontSize: 13,
      lineHeight: 1.3,
      p: (theme) => theme.spacing(1.5, 2),
      boxShadow: `0 0 4px rgba(0,0,0,0.2)`,
    },
    arrow: {
      color: color,
    },
    icon: {
      color: color,
    },
  });
};
