import React, { FC, ReactNode } from 'react';

import { SHARED_PALETTE } from '@lib/theme/constants';
import { SxStyles } from '@lib/theme/sxTheme';
import { Box } from '@mui/material';

interface Props {
  children: ReactNode;
}

export const DefaultCardFooter: FC<Props> = (props) => {
  const { children } = props;

  return <Box sx={styles.getValue('footerContainer')}>{children}</Box>;
};

const styles = new SxStyles({
  footerContainer: {
    display: 'flex',
    width: '100%',
    borderBottom: `1px solid ${SHARED_PALETTE.darkGrey}`,
  },
});
