import { ChangeEvent, ReactElement, ReactNode } from 'react';
import {
  Control,
  FieldPath,
  FieldValues,
  useFormContext,
} from 'react-hook-form';

import { getFormError } from '@lib/form/errors';
import { SxStyles } from '@lib/theme/sxTheme';
import { Box, FormControl, TextField, TextFieldProps } from '@mui/material';

import { ErrorText } from '../ErrorText';
import { PlainText } from '../PlainText';
import { CharCounter } from './CharCounter';

type Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = TextFieldProps & {
  name: TName;
  control: Control<TFieldValues>;
  readOnly?: boolean;
  sideIcon?: ReactNode;
  sideIconOffset?: boolean;
  counterLimit?: number;
};

export const TextInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: Props<TFieldValues, TName>
): ReactElement | null => {
  const {
    label,
    name = '',
    fullWidth = true,
    type = 'text',
    readOnly,
    InputLabelProps,
    variant = 'outlined',
    sideIcon,
    inputProps,
    counterLimit,
    ...restProps
  } = props;
  const formMethods = useFormContext();
  const { watch, setValue: setFormValue, formState, register } = formMethods;
  const { ref } = register(name);
  const formValue = watch(name) ?? '';
  const error = getFormError(name, formState.errors);
  const dynamicStyles = styles({ readOnly, hasErrors: !!error });

  const textField = readOnly ? (
    <PlainText
      inputLabelProps={InputLabelProps}
      label={label as string}
      value={formValue}
    />
  ) : (
    <FormControl
      error={!!error}
      component="fieldset"
      variant={variant}
      fullWidth={true}
      aria-labelledby={name}
      ref={ref}
    >
      <TextField
        sx={dynamicStyles.getValue('textFieldContainer')}
        id={name}
        error={!!error}
        label={label as string}
        value={formValue}
        onChange={handleChange}
        type={type}
        InputLabelProps={InputLabelProps}
        inputProps={{
          'data-testid': name,
          ...inputProps,
        }}
        fullWidth={fullWidth}
        variant={variant}
        name={name}
        {...restProps}
      />
      <Box sx={dynamicStyles.getValue('errorContainer')}>
        <ErrorText error={error} />
        {counterLimit && (
          <CharCounter
            sx={dynamicStyles.getValue('charCounter')}
            fieldName={name}
            counterLimit={counterLimit}
          ></CharCounter>
        )}
      </Box>
    </FormControl>
  );

  if (sideIcon) {
    return (
      <Box display="flex" alignItems="flex-end">
        <Box sx={dynamicStyles.getValue('sideIconContainer')}>{sideIcon}</Box>
        {textField}
      </Box>
    );
  }

  return textField;

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const val = event.target.value;

    setFormValue(name, val, {
      shouldValidate: formState.isDirty,
      shouldDirty: true,
    });
  }
};

interface StyleProps {
  readOnly: Props['readOnly'];
  hasErrors: boolean;
}

const styles = ({ readOnly, hasErrors }: StyleProps) =>
  new SxStyles({
    textFieldContainer: {
      '& > .Mui-focused .MuiInputAdornment-root .MuiSvgIcon-root': {
        color: 'primary.main',
      },
    },
    sideIconContainer: {
      display: 'flex',
      overflow: 'hidden',
      width: 32,
      alignItems: 'center',
      margin: (theme) => {
        if (readOnly) {
          return theme.spacing(0, 1, 0, 0);
        }
        if (hasErrors) {
          return 'auto 8px 25px 0px';
        }

        return theme.spacing('auto', 1, 0.25, 0);
      },
      color: 'primary.main',
    },
    errorContainer: {
      display: 'flex',
    },
    charCounter: {
      marginLeft: 'auto',
    },
  });
