import { useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { APP_ROUTES } from '@lib/route/constants';
import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import { SxStyles } from '@lib/theme/sxTheme';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Button, Menu, MenuItem, useTheme } from '@mui/material';
import { TextLink } from '@ui/buttons/TextLink';
import { Specifics } from '@lib/tenants/types';
import { Theme } from '@mui/system';

export const UserProfileMenu: React.FC = () => {
  const { t } = useTranslation();
  const menuButtonRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setOpen] = useState(false);
  const { tenant } = useTenantProps();
  const specificColors = tenant.theme?.specifics;
  const theme = useTheme();
  const dynamicStyles = styles({
    specificColors,
    theme,
  });

  const handleMenuOpen = () => {
    setOpen(true);
  };

  const handleMenuClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        sx={dynamicStyles.getValue('button')}
        ref={menuButtonRef}
        onClick={handleMenuOpen}
        endIcon={
          isOpen ? (
            <ExpandLess sx={dynamicStyles.getValue('expandIcon')} />
          ) : (
            <ExpandMore sx={dynamicStyles.getValue('expandIcon')} />
          )
        }
        data-testid="user-profile-button"
      >
        {t('home:auth.userProfileMenu.buttonTitle')}
      </Button>
      <Menu
        anchorEl={menuButtonRef.current}
        open={isOpen}
        onClose={handleMenuClose}
        data-testid="user-profile-menu"
      >
        <MenuItem onClick={handleMenuClose}>
          <TextLink
            href={APP_ROUTES.PROFILE.PERSONAL_DETAILS.path}
            sx={dynamicStyles.getValue('link')}
          >
            {t('home:auth.userProfileMenu.personalDetails')}
          </TextLink>
        </MenuItem>
        <MenuItem onClick={handleMenuClose}>
          <TextLink
            href={APP_ROUTES.PROFILE.SPENDING_REPORT.path}
            sx={dynamicStyles.getValue('link')}
          >
            {t('home:auth.userProfileMenu.spendingReport')}
          </TextLink>
        </MenuItem>
      </Menu>
    </>
  );
};

interface StyleProps {
  specificColors?: Specifics;
  theme: Theme;
}

const styles = ({ specificColors, theme }: StyleProps) =>
  new SxStyles({
    link: {
      color: 'primary.main',
    },
    button: {
      borderRadius: '16px',
      textTransform: 'none',
      border: `1px solid ${
        specificColors?.userCard?.borderColor || theme.palette.primary.light
      }`,
      color: 'light.main',
      backgroundColor:
        specificColors?.userCard?.backgroundColor || 'primary.main',
    },
    expandIcon: {
      color: 'light.main',
    },
  });
