import { SupportFormValues } from './types';

export const DESCRIPTION_LIMIT = 500;

export const DEFAULT_SUPPORT_FORM_VALUES: SupportFormValues = {
  category: '',
  subcategory: '',
  description: '',
  communicationMethod: '',
};
