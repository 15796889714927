interface ReturnType {
  promise: () => Promise<unknown>;
  stop: () => void;
}

export const delay = (ms: number): ReturnType => {
  let timeout;

  const promise = () =>
    new Promise((resolve) => {
      timeout = setTimeout(resolve, ms);
    });
  const stop = () => {
    clearTimeout(timeout);
  };

  return {
    promise,
    stop,
  };
};
