import { ReactElement, SyntheticEvent } from 'react';

import { Chip } from '@mui/material';

import { UpdateFormValue } from './types';

interface Props {
  label: string;
  id: string;
  selectedIds: string[];
  updateFormValue: UpdateFormValue;
}

export const SelectChip = (props: Props): ReactElement => {
  const { label, updateFormValue, selectedIds, id } = props;

  return (
    <Chip
      label={label}
      onMouseDown={neutralizeEvent}
      onDelete={() => handleChipDelete(id)}
    />
  );

  function neutralizeEvent(event: SyntheticEvent): void {
    event.stopPropagation();
    event.preventDefault();
  }

  function handleChipDelete(idToDelete: string): void {
    const val = selectedIds.filter((id) => id !== idToDelete);

    updateFormValue(val);
  }
};
