import { ChangeEvent, MouseEvent, useState } from 'react';

import { SxStyles } from '@lib/theme/sxTheme';
import Box from '@mui/material/Box';
import MaterialTable from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import dynamic from 'next/dynamic';

import { ARIA_TABLE_ID, ROWS_PER_PAGE_OPTIONS } from './constants';
import { TableToolbar } from './TableToolbar';
import { TableFilteredHeadCell, TableHeadCell, TableOrder } from './types';

interface Props<T> {
  rows: T[];
  headCells: TableHeadCell<T>[];
  orderBy: keyof T;
  order: TableOrder;
  count?: number;
  tableName?: string;
  isLoading?: boolean;
  rowsPerPage?: number[];
  disablePagination?: boolean;
  onChangePage?: (newPage: number, limit: number) => void;
  onChangeRowsPerPage?: (newLimit: number) => void;
}

const TableHead: any = dynamic(() => import('./TableHead'), {
  ssr: false,
});
const TableBody: any = dynamic(() => import('./table-body/TableBody'), {
  ssr: false,
});

export const Table = <T,>({
  rows,
  tableName,
  isLoading = false,
  headCells,
  order: initialOrder,
  orderBy: initialOrderBy,
  rowsPerPage: initialRowsPerPage,
  disablePagination = false,
  count,
  onChangePage,
  onChangeRowsPerPage,
}: Props<T>): JSX.Element => {
  const [order, setOrder] = useState<TableOrder>(initialOrder);
  const [orderBy, setOrderBy] =
    useState<TableHeadCell<T>['id']>(initialOrderBy);

  const [filterBy, setFilterBy] = useState<TableFilteredHeadCell<T>>({
    headCell: headCells[0],
    filterValue: '',
  });
  const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());
  const filteredRows = getFilteredRows();
  const [page, setPage] = useState(0);
  const rowsPerPageOptions = initialRowsPerPage ?? ROWS_PER_PAGE_OPTIONS;
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  return (
    <Box sx={styles.getValue('root')}>
      {tableName && <TableToolbar tableName={tableName} />}
      <TableContainer>
        <MaterialTable
          sx={styles.getValue('table')}
          aria-labelledby={ARIA_TABLE_ID}
          size="medium"
        >
          <TableHead
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            filterBy={filterBy}
            onFilterChange={handleChangeFilterBy}
          />
          <TableBody
            serverSidePaginationOn={!!onChangePage}
            rows={filteredRows}
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            page={page}
            rowsPerPage={rowsPerPage}
            isLoading={isLoading}
            expandedRows={expandedRows}
            onToggleExpandRow={handleToggleExpandRow}
          />
        </MaterialTable>
      </TableContainer>
      {!disablePagination && (
        <TablePagination
          sx={{
            mt: 2,
          }}
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={count ?? filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={!!onChangePage ? (!count || count <= 0 ? 0 : page) : page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Box>
  );

  function handleRequestSort(
    event: MouseEvent<unknown>,
    property: TableHeadCell<T>['id'],
  ): void {
    const isAsc = orderBy === property && order === 'asc';

    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPage(0);
  }

  function handleChangePage(event: unknown, newPage: number): void {
    setPage(newPage);
    onChangePage && onChangePage(newPage + 1, rowsPerPage);
  }

  function handleChangeRowsPerPage(event: ChangeEvent<HTMLInputElement>): void {
    setRowsPerPage(parseInt(event.target.value, 10));
    onChangeRowsPerPage &&
      onChangeRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function handleChangeFilterBy(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    headCell: TableHeadCell<T>,
  ): void {
    setFilterBy?.({
      headCell: headCell,
      filterValue: event.target.value,
    });
    // reset pagination in case current page is out of range of filtered rows
    setPage(0);
  }

  function handleToggleExpandRow(id: string): void {
    setExpandedRows((prev) => {
      const newExpandedRows = new Set(prev);
      if (newExpandedRows.has(id)) {
        newExpandedRows.delete(id);
      } else {
        newExpandedRows.add(id);
      }
      return newExpandedRows;
    });
  }

  function initialSorting(rows: T[]): T[] {
    if (order && orderBy) {
      const sortedRows = rows.sort((a, b) => {
        const valueA = a[orderBy];
        const valueB = b[orderBy];
        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA.localeCompare(valueB);
        }
        return valueA > valueB ? 1 : -1;
      });
      return order === 'asc' ? sortedRows : sortedRows.reverse();
    } else return rows;
  }

  function getFilteredRows(): T[] {
    if (filterBy?.filterValue && filterBy?.headCell.filterFn) {
      const rowsToFilter = !!onChangePage ? rows : initialSorting(rows);
      return rowsToFilter.filter((row) =>
        filterBy.headCell.filterFn?.(row, filterBy.filterValue),
      );
    }
    return !!onChangePage ? rows : initialSorting(rows);
  }
};

const styles = new SxStyles({
  root: {
    width: '100%',
  },
  container: {
    width: '100%',
    mb: 2,
  },
  table: {
    minWidth: 750,
  },
});
