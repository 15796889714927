import { FC, ReactNode } from 'react';

import { useToggle } from '@lib/useToggle';
import CloseIcon from '@mui/icons-material/Close';
import Alert, { AlertColor } from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';

interface Props {
  severity: AlertColor;
  children: ReactNode;
}

export const CollapsableAlert: FC<Props> = (props) => {
  const { children, severity } = props;
  const { toggleOff, isToggled } = useToggle(true);

  return (
    <Box>
      <Collapse in={isToggled}>
        <Alert
          severity={severity}
          action={
            <IconButton aria-label="close" size="small" onClick={toggleOff}>
              <CloseIcon />
            </IconButton>
          }
        >
          {children}
        </Alert>
      </Collapse>
    </Box>
  );
};
