import { FC, useState } from 'react';
import { Box } from '@mui/material';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { StripePaymentElementOptions } from '@stripe/stripe-js';
import { Button } from '@ui/buttons/Button';
import { PriceFormat } from '@ui/price/PriceFormat';
import { useTranslation } from 'next-i18next';
import { toast } from 'react-toastify';

interface Props {
  totalUpfront: string | number;
  callbackUrl: string;
  currency: string;
  onClose: () => void;
}

export const CheckoutForm: FC<Props> = ({
  totalUpfront,
  callbackUrl,
  currency,
  onClose,
}) => {
  const stripe = useStripe();
  const { t } = useTranslation();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (!stripe || !elements) {
      toast.error(t('common:errors.unexpected'));
      onClose();
      return;
    }

    try {
      setIsLoading(true);
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: callbackUrl,
        },
      });

      const errorMessage = error?.message;
      const errorType = error?.type;

      if (
        errorMessage &&
        (errorType === 'card_error' || errorType === 'validation_error')
      ) {
        toast.error(errorMessage);
        onClose();
        return;
      }

      if (errorType) {
        toast.error(`${errorType}: ${t('common:errors.unexpected')}`);
        onClose();
        return;
      }
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : t('common:errors.unexpected');
      toast.error(errorMessage);
      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: {
      type: 'tabs',
      defaultCollapsed: false,
    },
  };

  const roundedTotalUpfront = Number(totalUpfront);

  return (
    <form id="payment-form" onSubmit={handleSubmit} data-testid="payment-form">
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <Box
        sx={{
          display: 'flex',
          my: 2,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          disabled={isLoading || !stripe || !elements}
          id="submit"
          loading={isLoading}
          type="submit"
          aria-busy={isLoading}
          aria-disabled={isLoading || !stripe || !elements}
        >
          {t('common:stripe-payment-modal.pay')}
          <PriceFormat price={roundedTotalUpfront} currency={currency} />
        </Button>
      </Box>
    </form>
  );
};
