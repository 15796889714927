import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack } from '@mui/material';
import { ContentModal } from '@ui/content-modal/ContentModal';
import { QRCodeSVG } from 'qrcode.react';

interface Props {
  open: boolean;
  onClose(): void;
  qrCode?: string;
}

export const ViewInventoryQRModal: FC<Props> = ({ open, onClose, qrCode }) => {
  const { t } = useTranslation('common');

  if (!open || !qrCode) return null;

  return (
    <ContentModal
      titleLabel={t('view-inventory-qr-modal.title')}
      showCloseModalIcon
      isOpen={open}
      onCancel={onClose}
      minWidth="350px"
    >
      <Stack mb={1} justifyContent="center" alignItems="center">
        <QRCodeSVG size={180} value={qrCode} />
      </Stack>
    </ContentModal>
  );
};
