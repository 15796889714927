import { FC, ReactNode, useMemo } from 'react';
import { useTranslation } from 'next-i18next';

import { SubscriptionProductChild } from '@api/ocb-digital/subscription/types';
import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import { SxTheme } from '@lib/theme/types';
import { Stack, Typography, useTheme } from '@mui/material';
import { DefaultCard } from '@ui/cards/default-card/DefaultCard';

import { mapToOfferCardLines } from './mapToOfferCardLines';
import { useLocalisedDate } from '@lib/time';

interface Props {
  product: SubscriptionProductChild;
  isBasePackage: boolean;
  actions?: React.ReactNode;
  sx?: SxTheme;
  button?: ReactNode;
}

export const SubscriptionCard: FC<Props> = ({
  product,
  isBasePackage,
  actions,
  sx,
  button,
}) => {
  const { t } = useTranslation();
  const { formatDateTimeTz } = useLocalisedDate();

  const lines = useMemo(
    () =>
      mapToOfferCardLines(product, isBasePackage, t, formatDateTimeTz).map(
        (line, index) => (
          <Stack
            key={index}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography component="div">{line.label}</Typography>
            <Typography component="div">{line.value}</Typography>
          </Stack>
        ),
      ),
    [product, isBasePackage, t, formatDateTimeTz],
  );
  const theme = useTheme();
  const { tenant } = useTenantProps();
  const specificBackgroundColor =
    tenant.theme?.specifics?.authHomePageCard?.backgroundColor;

  return (
    <DefaultCard
      bgcolor={specificBackgroundColor || '#E8F5FF'}
      noBorder={false}
      borderColor={theme.palette.primary.main}
      sx={{ minHeight: '220px', ...sx }}
      title={product.product.commercialName}
      button={button}
    >
      {lines}
      {actions}
    </DefaultCard>
  );
};
