import React, { FC, ReactNode } from 'react';

import { SxTheme } from '@lib/theme/types';
import { Box } from '@mui/material';

interface Props {
  isContentPadded: boolean;
  contentBackgroundColor: string;
  children: ReactNode;
  contentSx: SxTheme | undefined;
}

export const DefaultCardContent: FC<Props> = ({
  contentSx,
  contentBackgroundColor,
  isContentPadded,
  children,
}) => {
  return (
    <Box
      p={isContentPadded ? 2 : 0}
      bgcolor={contentBackgroundColor}
      height="100%"
      sx={contentSx}
    >
      {children}
    </Box>
  );
};
