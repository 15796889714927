import React, { PropsWithChildren } from 'react';

import { Modal } from '@mui/material';

import { ContentModalLayout } from './ContentModalLayout';

interface Props {
  isOpen: boolean;
  titleLabel?: string;
  submitLabel?: string;
  cancelLabel?: string;
  isLoading?: boolean;
  minWidth?: string;
  maxWidth?: string;
  onSubmit?(data?: unknown): void;
  onCancel(): void;
  isSubmitDisabled?: boolean;
  keepMounted?: boolean;
  showCloseModalIcon?: boolean;
}

export const ContentModal: React.FC<PropsWithChildren<Props>> = ({
  isOpen,
  keepMounted = false,
  children,
  ...rest
}) => {
  return (
    <Modal
      aria-labelledby="modal"
      aria-describedby="required action modal"
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      open={isOpen}
      onClose={rest.onCancel}
      closeAfterTransition
      slotProps={{ backdrop: { timeout: 500 } }}
      keepMounted={keepMounted}
    >
      <ContentModalLayout {...rest}>{children}</ContentModalLayout>
    </Modal>
  );
};
