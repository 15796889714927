import { FC, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { Stack, Typography } from '@mui/material';
import { SubscriptionsContextProps } from '@lib/subscriptions/SubscriptionsContext';
import { InfoTooltip } from '@ui/tooltip/InfoTooltip';

interface Props {
  selectedSubscription: SubscriptionsContextProps['selectedSubscription'];
}

export const SubscriptionMainProductTitleNote: FC<Props> = ({
  selectedSubscription,
}) => {
  const { t } = useTranslation();
  const status = selectedSubscription?.status
    ? t(`home:auth.subscription.statuses.${selectedSubscription?.status}`)
    : '';
  const statusReason = selectedSubscription?.statusReason
    ? t(
        `home:auth.subscription.status-reasons.${selectedSubscription?.statusReason}`,
      )
    : '';

  const titleNote =
    selectedSubscription?.status === 'SUSPENDED'
      ? `${status} - ${statusReason}`
      : status;

  const tooltip = useMemo(() => {
    if (selectedSubscription?.status === 'PENDING') {
      return t('home:auth.subscription.tooltip.pending');
    }
    if (
      selectedSubscription?.status === 'SUSPENDED' &&
      selectedSubscription?.statusReason === 'NON_PAYMENT'
    ) {
      return t('home:auth.subscription.tooltip.nonPayment');
    }

    return '';
  }, [selectedSubscription, t]);

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography color="warning.light" fontSize={20} fontWeight={500}>
        {titleNote}
      </Typography>
      {tooltip && <InfoTooltip title={tooltip} />}
    </Stack>
  );
};
