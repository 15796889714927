import { FC } from 'react';
import { NumericFormat } from 'react-number-format';
import { useTranslation } from 'next-i18next';
import { is } from 'ramda';

import { Box } from '@mui/material';

import { SYMBOLS } from '@lib/placeholders/constants';
import { SxTheme } from '@lib/theme/types';
import { useDecimalScale } from '@lib/price';

interface Props {
  price?: number | null;
  currency?: string;
  sx?: SxTheme;
}

export const PriceFormat: FC<Props> = ({
  price,
  currency = SYMBOLS.emptyText,
  sx,
}) => {
  const { t } = useTranslation();
  const decimalScale = useDecimalScale();
  const value = is(Number, price) ? price : SYMBOLS.emptyText;
  const currencySign = t(`common:currency.${currency}`, {
    defaultValue: '',
  });
  const prefix = stringOrUndefined(currencySign);
  const suffix = prefix ? undefined : stringOrUndefined(currency);

  return (
    <Box sx={sx} component="span">
      <NumericFormat
        decimalScale={decimalScale}
        fixedDecimalScale
        displayType="text"
        thousandSeparator={true}
        value={value}
        prefix={prefix}
        suffix={suffix && ` ${suffix}`}
      />
    </Box>
  );
};

function stringOrUndefined(value?: string) {
  if (!value?.length) return undefined;

  return value;
}
