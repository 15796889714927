import React, { FC } from 'react';

import { Grid, IconButton, Tooltip } from '@mui/material';

import { HeaderAction } from '../types';

interface Props {
  actions: HeaderAction[] | undefined;
}

export const DefaultCardHeaderActions: FC<Props> = (props) => {
  const { actions } = props;

  if (!actions) {
    return null;
  }

  return (
    <Grid item container xs={6} spacing={1} justifyContent="flex-end">
      {actions.map(
        (action, index) =>
          !action.isHidden && (
            <Grid item key={index}>
              <Tooltip title={action.tooltip}>
                <IconButton
                  size="small"
                  aria-label={action.ariaLabel}
                  onClick={action.onClick}
                >
                  {action.icon}
                </IconButton>
              </Tooltip>
            </Grid>
          )
      )}
    </Grid>
  );
};
