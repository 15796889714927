import { FC } from 'react';
import { useTranslation } from 'next-i18next';

import { CenteredLoader } from '@ui/loaders/CenteredLoader';
import { ErrorPlaceholder } from '@ui/placeholders/ErrorPlaceholder';
import { NoDataPlaceholder } from '@ui/placeholders/NoDataPlaceholder';

import { SubscriptionCard } from '../common/SubscriptionCard';
import { Button } from '@ui/buttons/Button';
import { APP_ROUTES } from '@lib/route/constants';
import { ContentModal } from '@ui/content-modal/ContentModal';
import { AddonBalance } from '../subscription-addons/addon-balance/AddonBalanceContent';
import { Box } from '@mui/material';
import { SxStyles } from '@lib/theme/sxTheme';
import { useRouter } from 'next/router';
import { useRequestBalance } from '@lib/balance/useRequestBalance';
import { CACHE_KEYS } from '@lib/fetch/constants';
import { useToggle } from '@lib/useToggle';
import { SubscriptionProduct } from '@api/ocb-digital/subscription/types';
import { useManageSubscriptions } from '../common/useManageSubscriptions';

interface Props {
  isSubscriptionsFetching: boolean;
  isSubscriptionsLoading: boolean;
  isSubscriptionsError: boolean;
  subscriptionMainProduct?: SubscriptionProduct;
}

export const SubscriptionMainProductContent: FC<Props> = ({
  isSubscriptionsFetching,
  isSubscriptionsLoading,
  isSubscriptionsError,
  subscriptionMainProduct,
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { refetch: fetchAddonsBalance } = useRequestBalance(
    CACHE_KEYS.addonsBalance,
  );
  const {
    isToggled: isViewBalanceModalOpen,
    toggleOff: closeViewBalanceModal,
    toggleOn: openViewBalanceModal,
  } = useToggle();

  const { isManageButtonVisible, isManageButtonEnabled } =
    useManageSubscriptions();

  if (isSubscriptionsFetching || isSubscriptionsLoading) {
    return <CenteredLoader />;
  }

  if (isSubscriptionsError) {
    return <ErrorPlaceholder />;
  }

  if (subscriptionMainProduct) {
    return (
      <>
        <SubscriptionCard
          product={subscriptionMainProduct}
          isBasePackage={true}
          actions={
            <Box sx={styles.getValue('actionsWrapper')}>
              <Button
                onClick={onOpenBalanceModal}
                sx={styles.getValue('viewBalanceButton')}
              >
                {t('home:auth.addons.buttons.viewBalance')}
              </Button>
            </Box>
          }
          button={
            isManageButtonVisible ? (
              <Button
                onClick={navigateToModifySubscription}
                disabled={!isManageButtonEnabled}
              >
                {t('profile:stepper.addons.change')}
              </Button>
            ) : undefined
          }
        />
        <ContentModal
          titleLabel={t('home:auth.addons.balanceModal.title')}
          onCancel={onCloseBalanceModal}
          isOpen={isViewBalanceModalOpen}
          showCloseModalIcon
        >
          <AddonBalance productId={subscriptionMainProduct.productId} />
        </ContentModal>
      </>
    );
  }

  if (!subscriptionMainProduct) {
    return <NoDataPlaceholder title={t('common:errors.noActivePackages')} />;
  }

  return null;

  function onCloseBalanceModal() {
    closeViewBalanceModal();
  }

  function onOpenBalanceModal() {
    fetchAddonsBalance();
    openViewBalanceModal();
  }

  function navigateToModifySubscription() {
    router.push(APP_ROUTES.PROFILE.MODIFY_SUBSCRIPTION.path);
  }
};

const styles = new SxStyles({
  actionsWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  viewBalanceButton: {
    textTransform: 'none',
  },
});
