import { api } from '@api/index';
import { CACHE_KEYS } from '@lib/fetch/constants';
import { useApiQuery } from '@lib/fetch/useApiQuery';
import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import { DefaultCard } from '@ui/cards/default-card/DefaultCard';
import { PriceFormat } from '@ui/price/PriceFormat';
import { Table } from '@ui/table/Table';
import { TableHeadCell } from '@ui/table/types';
import { useTranslation } from 'next-i18next';
import { FC, ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { OrderTableItems } from './types';
import { useLocalisedDate } from '@lib/time';
import {
  PaymentRenewalModal,
  PaymentRenewalModalRef,
} from './PaymentRenewalModal';
import { Button } from '@ui/buttons/Button';
import { OrderDetails } from '@api/ocb-digital/order/types';
import { SYMBOLS } from '@lib/placeholders/constants';
import { formatSubscriptionsCell } from './formatSubscriptionsCell';
import { Box, Stack } from '@mui/material';
import { InfoTooltip } from '@ui/tooltip/InfoTooltip';
import { DEFAULT_PAGE_SIZE } from '@ui/table/constants';

const VISIBLE_ORDER_TYPES =
  'NEW_SUBSCRIPTION,MODIFY_SUBSCRIPTION,TOP_UP,CHANGE_SUBSCRIPTION_STATUS';
const ORDER_STATUSES_WITH_TOOLTIPS = [
  'PAYMENT',
  'PAYMENT_FAILED',
  'FAILED',
  'ORDER_ITEM_ACTIVATION',
  'CANCELLED',
];

export const Orders: FC = () => {
  const { tenant } = useTenantProps();
  const { formatDateTimeTz } = useLocalisedDate();
  const { t } = useTranslation('home');
  const [startRow, setStartRow] = useState(0);
  const [endRow, setEndRow] = useState(DEFAULT_PAGE_SIZE);
  const {
    data: orders,
    isFetching,
    refetch,
  } = useApiQuery(fetchOrders, {
    queryKey: [
      CACHE_KEYS.customerOrders(startRow.toString(), endRow.toString()),
    ],
  });
  const modalRef = useRef<PaymentRenewalModalRef>(null);
  const onOpenPayModal = useCallback((order: OrderDetails) => {
    modalRef.current?.openModal(order);
  }, []);
  const translateStatus = useCallback(
    (status: string) => {
      return t(`auth.orders.table.statuses.${status}`);
    },
    [t],
  );
  const tableHeaderCells: TableHeadCell<
    OrderTableItems & { actions: ReactNode }
  >[] = [
    {
      id: 'createdAt',
      formatLabel: () => t('auth.orders.table.header.createdAt'),
      formatRowValue: (value) =>
        value?.createdAt
          ? formatDateTimeTz(value?.createdAt)
          : SYMBOLS.emptyText,
    },
    {
      id: 'orderType',
      formatLabel: () => t('auth.orders.table.header.orderType'),
      formatRowValue: (value) =>
        value?.orderType
          ? t(`auth.orders.table.types.${value?.orderType}`)
          : SYMBOLS.emptyText,
    },
    {
      id: 'subscriptions',
      formatLabel: () => t('auth.orders.table.header.subscriptionId'),
      formatRowValue: (value) =>
        formatSubscriptionsCell(translateStatus, value?.subscriptions),
    },
    {
      id: 'amount',
      formatLabel: () => t('auth.orders.table.header.amount'),
    },
    {
      id: 'orderStatus',
      formatLabel: () => t('auth.orders.table.header.orderStatus'),
      formatRowValue: (value) => formatOrderStatus(value?.orderStatus),
    },

    {
      id: 'actions',
      formatLabel: () => '',
    },
  ];
  const rows = useMemo(() => {
    return orders?.content?.map((order) => ({
      createdAt: order?.createdAt,
      orderType: order?.orderType,
      subscriptions: order?.orderItems
        ?.map(({ subscriptionId, orderItemStatus, identifier }) => ({
          subscriptionId,
          orderItemStatus,
          identifier,
        }))
        .filter((sub) => sub.subscriptionId || sub.identifier),
      amount: (
        <PriceFormat
          price={order?.orderPayment?.amount?.amount}
          currency={order?.orderPayment?.amount?.currency}
        />
      ),
      orderStatus: order?.orderStatus,
      actions: order?.payable && (
        <Button size="small" onClick={() => onOpenPayModal(order)}>
          {t('auth.orders.table.actions.pay')}
        </Button>
      ),
    }));
  }, [orders?.content, t, onOpenPayModal]);

  return (
    <DefaultCard
      title={t('auth.orders.title')}
      noBorder={false}
      data-testid="orders-panel"
    >
      <Table
        rows={rows ?? []}
        headCells={tableHeaderCells}
        orderBy="createdAt"
        order="desc"
        isLoading={isFetching}
        count={orders?.lastRow}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
      <PaymentRenewalModal ref={modalRef} refetchPayments={refetch} />
    </DefaultCard>
  );

  async function fetchOrders() {
    try {
      return await api.ocbDigital.order.getOrders(tenant, {
        startRow: startRow.toString(),
        endRow: endRow.toString(),
        sort: 'createdAt,desc',
        filter: `orderType,in,${VISIBLE_ORDER_TYPES}`,
      });
    } catch (error) {
      throw error;
    }
  }

  async function onChangeRowsPerPage(newLimit: number) {
    try {
      setStartRow(0);
      setEndRow(newLimit);
    } catch (error) {
      throw error;
    }
  }

  async function onChangePage(newPage: number, limit: number) {
    try {
      const startRow = Math.max((newPage - 1) * limit, 0);
      const endRow = Math.max(newPage * limit, 10);
      setStartRow(startRow);
      setEndRow(endRow);
    } catch (error) {
      throw error;
    }
  }

  function formatOrderStatus(orderStatus: OrderDetails['orderStatus']) {
    if (!orderStatus) {
      return SYMBOLS.emptyText;
    }

    const status = t(`auth.orders.table.statuses.${orderStatus}`);

    if (ORDER_STATUSES_WITH_TOOLTIPS.includes(orderStatus)) {
      return (
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{ mt: -0.25 }}
        >
          <Box>{status}</Box>
          <InfoTooltip title={t(`auth.orders.table.tooltip.${orderStatus}`)} />
        </Stack>
      );
    }

    return status;
  }
};
