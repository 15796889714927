import { add } from 'ramda';

import { ProductPricing } from '@lib/offers/types';

import { PriceFormat } from './PriceFormat';
import { PriceDisplayType } from './types';

interface Props {
  pricing?: ProductPricing;
  fontSize?: number | string;
  display?: PriceDisplayType;
}

export const OneTimeFee: React.FC<Props> = ({
  pricing,
  fontSize = '24px',
  display = 'productCard',
}) => {
  if (!pricing?.oneTimeFee) {
    return (
      <PriceFormat
        price={undefined}
        currency={undefined}
        sx={{
          ...(display === 'productCard' && { fontWeight: 'fontWeightBold' }),
          lineHeight: 'initial',
          fontSize,
        }}
      />
    );
  }

  const finalPrice = pricing?.oneTimeFee?.price ?? 0;
  const discount = pricing?.oneTimeFee?.discount ?? 0;
  const totalPrice = add(finalPrice, discount);
  const hasDiscount = discount > 0;
  const currency = pricing?.oneTimeFee?.currency;

  return (
    <>
      {hasDiscount && display === 'productCard' && (
        <PriceFormat
          price={totalPrice}
          currency={currency}
          sx={{
            textDecoration: 'line-through',
            lineHeight: 'initial',
            fontSize,
          }}
        />
      )}
      <PriceFormat
        price={finalPrice}
        currency={currency}
        sx={{
          ...(display === 'productCard' && { fontWeight: 'fontWeightBold' }),
          lineHeight: 'initial',
          fontSize,
        }}
      />
    </>
  );
};
