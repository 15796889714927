import { InventoryItem } from '@api/ocb-digital/subscription/types';
import { ContentModal } from '@ui/content-modal/ContentModal';
import { useTranslation } from 'next-i18next';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { toast } from 'react-toastify';
import { useApiMutation } from '@lib/fetch/useApiMutation';
import { api } from '@api/index';
import { useTenantProps } from '@lib/tenants/TenantPropsContext';

export interface ResendInventoryQRModalRef {
  openModal: (inventoryItem: InventoryItem) => void;
}

export const ResendInventoryQRModal = forwardRef(
  function ResendInventoryQRModal({}, ref) {
    const { t } = useTranslation('home');
    const { tenant } = useTenantProps();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedInventoryItem, setSelectedInventoryItem] = useState<
      InventoryItem | undefined
    >();
    const { mutateAsync, isPending } = useApiMutation(resendInventoryQRCode);

    useImperativeHandle(
      ref,
      () => ({
        openModal(inventoryItem: InventoryItem) {
          setSelectedInventoryItem(inventoryItem);
          setIsOpen(true);
        },
      }),
      [],
    );

    return (
      <ContentModal
        keepMounted={false}
        titleLabel={t('auth.resources.resendInventoryQRModal.title')}
        isOpen={isOpen}
        onCancel={onCloseModal}
        cancelLabel={t('auth.resources.resendInventoryQRModal.cancelButton')}
        showCloseModalIcon
        isLoading={isPending}
        submitLabel={t('auth.resources.resendInventoryQRModal.sendButton')}
        onSubmit={handleSubmit}
        maxWidth="sm"
      >
        {t('auth.resources.resendInventoryQRModal.content')}
      </ContentModal>
    );

    async function resendInventoryQRCode() {
      if (!selectedInventoryItem?.inventoryId) {
        throw new Error(t('common:errors.unexpected'));
      }
      await api.ocbDigital.inventory.resendInventoryQRCode(tenant, {
        inventoryId: selectedInventoryItem.inventoryId,
      });
      toast.success(t('auth.resources.resendInventoryQRModal.successMessage'));
      onCloseModal();
    }

    async function handleSubmit() {
      await mutateAsync();
    }

    function onCloseModal() {
      setIsOpen(false);
    }
  },
);
