import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { ParentProductRo } from '@api/ocb-digital/offer/types';
import { PriceCase, Product } from '@lib/offers/types';

import { PersonalInfoFormValues } from '../plans/personal-info-step/types';
import { PhoneNumberOptions } from './types';
import { Order } from '@lib/payment/types';
import {
  ProductFieldValue,
  SelectedProduct,
} from '@api/ocb-digital/order/types';
import { useInventoryTypeVariantsConfig } from '@lib/useInventoryTypeVariantsConfig';
import { PhoneNumberFormValues } from './phone-number-step/types';

export const PurchaseInfoProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { simVariantId } = useInventoryTypeVariantsConfig();
  const [order, setOrder] = useState<Order | null>(null);
  const [existingPhoneNumberForm, setExistingPhoneNumberForm] =
    useState<PhoneNumberFormValues | null>(null);
  const [selectedParentProduct, setSelectedParentProduct] =
    useState<ParentProductRo | null>(null);
  const [selectedProducts, setSelectedProducts] = useState<
    Record<string, Product[]>
  >({});
  const [selectedPhoneNumberOption, setSelectedPhoneNumberOption] =
    useState<PhoneNumberOptions>();
  const [selectedSimType, setSelectedSimType] = useState<string | undefined>(
    simVariantId,
  );
  const [selectedBundles, setSelectedBundles] = useState<PriceCase[]>([]);
  const [personalInfoForm, setPersonalInfoForm] =
    useState<PersonalInfoFormValues | null>(null);
  const [chargedServiceFeeProducts, setChargedServiceFeeProducts] = useState<
    SelectedProduct[] | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const totalPrice = useMemo(
    () => order?.totalPayment?.amount,
    [order?.totalPayment?.amount],
  );

  const productFieldValues = useMemo(
    () =>
      order?.offers?.flatMap((offer) =>
        recursivelyFindProductFieldValues(offer?.selectedProducts),
      ),
    [order?.offers],
  );

  useEffect(() => {
    setChargedServiceFeeProducts(
      getChargedServiceFeeProducts(selectedParentProduct, order),
    );
  }, [selectedParentProduct, order]);

  const containsRecurringFee = useMemo(() => {
    const hasRecurringFee = (product: Product) =>
      !!product.productPricing.recurringFee?.price;

    const selectedProductsArray = Object.values(selectedProducts).flat();
    const parentHasRecurringPrice =
      !!selectedParentProduct?.blob?.productPricing.recurringFee?.price;

    return (
      selectedProductsArray.some(hasRecurringFee) || parentHasRecurringPrice
    );
  }, [
    selectedParentProduct?.blob?.productPricing.recurringFee?.price,
    selectedProducts,
  ]);

  return (
    <PurchaseInfoContext.Provider
      value={{
        existingPhoneNumberForm,
        selectedParentProduct,
        personalInfoForm,
        selectedProducts,
        selectedBundles,
        totalPrice,
        containsRecurringFee,
        selectedPhoneNumberOption,
        order,
        selectedSimType,
        chargedServiceFeeProducts,
        setChargedServiceFeeProducts,
        setExistingPhoneNumberForm,
        setPersonalInfoForm,
        setSelectedParentProduct,
        setSelectedProducts,
        setSelectedBundles,
        setSelectedPhoneNumberOption,
        setOrder,
        setSelectedSimType,
        isLoading,
        setIsLoading,
        productFieldValues,
      }}
    >
      {children}
    </PurchaseInfoContext.Provider>
  );
};

export interface PurchaseInfoContextProps {
  existingPhoneNumberForm: PhoneNumberFormValues | null;
  selectedParentProduct: ParentProductRo | null;
  selectedProducts: Record<string, Product[]>;
  selectedBundles: PriceCase[];
  personalInfoForm: PersonalInfoFormValues | null;
  totalPrice?: number;
  containsRecurringFee: boolean;
  selectedPhoneNumberOption?: PhoneNumberOptions;
  order: Order | null;
  selectedSimType?: string;
  chargedServiceFeeProducts: SelectedProduct[] | undefined;
  setChargedServiceFeeProducts: Dispatch<
    SetStateAction<SelectedProduct[] | undefined>
  >;
  setSelectedPhoneNumberOption: Dispatch<
    SetStateAction<PhoneNumberOptions | undefined>
  >;
  setExistingPhoneNumberForm: Dispatch<
    SetStateAction<PhoneNumberFormValues | null>
  >;
  setSelectedParentProduct: Dispatch<SetStateAction<ParentProductRo | null>>;
  setSelectedProducts: Dispatch<SetStateAction<Record<string, Product[]>>>;
  setPersonalInfoForm: Dispatch<SetStateAction<PersonalInfoFormValues | null>>;
  setSelectedBundles: Dispatch<SetStateAction<PriceCase[]>>;
  setOrder: Dispatch<SetStateAction<Order | null>>;
  setSelectedSimType: Dispatch<SetStateAction<string | undefined>>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  productFieldValues?: ProductFieldValue[];
}

const PurchaseInfoContext = createContext<PurchaseInfoContextProps | undefined>(
  undefined,
);

export const usePurchaseInfo = (): PurchaseInfoContextProps => {
  const context = useContext(PurchaseInfoContext);

  if (context === undefined) {
    throw new Error(
      'usePurchaseInfo must be used within an PurchaseInfoProvider',
    );
  }

  return context;
};

function recursivelyFindProductFieldValues(
  selectedProducts: SelectedProduct[],
): ProductFieldValue[] {
  return selectedProducts.flatMap((selectedProduct) => {
    const productFieldValues = selectedProduct.productFieldValues ?? [];
    if (selectedProduct.children) {
      return [
        ...productFieldValues,
        ...recursivelyFindProductFieldValues(selectedProduct.children),
      ];
    }
    return [...productFieldValues];
  });
}

function getChargedServiceFeeProducts(
  selectedParentProduct: ParentProductRo | null,
  order: Order | null,
): SelectedProduct[] | undefined {
  const serviceFeesProductIds =
    selectedParentProduct?.blob?.serviceFees?.map(
      (serviceFee) => serviceFee.product.productId,
    ) ?? [];
  const chargedServiceFeeProducts = order?.offers?.flatMap((offer) =>
    offer.selectedProducts.flatMap((parentProduct) =>
      parentProduct.children?.filter((childProduct) =>
        serviceFeesProductIds.includes(childProduct.productId),
      ),
    ),
  );
  return chargedServiceFeeProducts as SelectedProduct[];
}
