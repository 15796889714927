import { FC, memo } from 'react';
import { useFormContext } from 'react-hook-form';

import { FormHelperText, FormHelperTextProps } from '@mui/material';

interface Props extends FormHelperTextProps {
  fieldName: string;
  counterLimit: number;
}

export const CharCounter: FC<Props> = memo((props) => {
  const { fieldName, counterLimit, ...restProps } = props;
  const { watch } = useFormContext();
  const value = watch(fieldName) || '';

  return (
    <FormHelperText
      {...restProps}
    >{`${value.length}/${counterLimit}`}</FormHelperText>
  );
});

CharCounter.displayName = 'CharCounter';
