import { useCallback } from 'react';
import usePortal, { Args, Return } from 'react-cool-portal';

interface ReturnType {
  Modal: Return['Portal'];
  isOpen: Return['isShow'];
  showModal: Return['show'];
  hideModal: () => void;
  toggleModal: Return['toggle'];
}

export const useModal = (options: Args = {}): ReturnType => {
  const {
    Portal,
    isShow: isOpen,
    show: showModal,
    hide: hideModal,
    toggle: toggleModal,
  } = usePortal({
    defaultShow: false,
    internalShowHide: false,
    clickOutsideToHide: false,
    ...options,
  });

  const Modal = useCallback(
    ({ children }) => <Portal>{children}</Portal>,
    [Portal]
  );

  return { Modal, isOpen, showModal, hideModal, toggleModal };
};
