import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import memoize from 'fast-memoize';

import { api } from '@api/index';
import { ClassifierIds } from '@api/ocb-digital/classifiers/types';
import { CACHE_KEYS } from '@lib/fetch/constants';
import { useApiQuery } from '@lib/fetch/useApiQuery';
import { SelectOption } from '@lib/form/types';
import { useTenantProps } from '@lib/tenants/TenantPropsContext';

interface ReturnType {
  getClassifier: (
    id: ClassifierIds,
    customSort?: (options: SelectOption[]) => SelectOption[],
  ) => SelectOption[];
  isLoading: boolean;
  isFetching: boolean;
}

//Classifiers populates XSRF-TOKEN cookie with data from the backend
export const useClassifiers = (): ReturnType => {
  const { t } = useTranslation();
  const { tenant } = useTenantProps();
  const { data, isLoading, isFetching } = useApiQuery(
    () => api.ocbDigital.classifiers.getAll(tenant),
    { queryKey: [CACHE_KEYS.classifiers] },
  );

  const getClassifier = useMemo(
    () =>
      memoize((id, customSort) => {
        const classifierMatches = data?.find(
          (classifier) => classifier.id === id,
        );
        const mappedValues: SelectOption[] =
          classifierMatches?.values.map((val) => ({
            id: val.id,
            label: t(`backend:classifiers.${id}.${val.id}`, {
              defaultValue: val.id,
            }),
          })) ?? [];

        const sorted = customSort
          ? customSort(mappedValues)
          : [...mappedValues].sort((a, b) => a.label.localeCompare(b.label));

        return sorted;
      }),
    [data, t],
  );

  return { getClassifier, isLoading, isFetching };
};
