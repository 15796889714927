import React, { FC, ReactNode } from 'react';

import { SupportedPaymentTypes } from '@lib/payment/types';
import { SHARED_PALETTE } from '@lib/theme/constants';
import { SxStyles } from '@lib/theme/sxTheme';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { Stack } from '@mui/material';
import { Box } from '@mui/system';

import { PaymentSelectOption } from '../types';

interface Props {
  option: PaymentSelectOption;
  selectedId: string;
  onClick: (id: SupportedPaymentTypes) => void;
}

export const PaymentSelectBox: FC<Props> = ({
  option,
  selectedId,
  onClick,
  ...rest
}) => {
  const isSelected = selectedId === option.id;
  const iconMap: Record<SupportedPaymentTypes, ReactNode> = {
    ONLINE: <CreditCardIcon />,
    PREPAID_BALANCE: <AccountBalanceWalletIcon />,
    DEALER_BALANCE: <AccountBalanceWalletIcon />,
    PAYPAL: <CreditCardIcon />,
    TLYNC: <CreditCardIcon />,
  };

  return (
    <Stack
      direction="row"
      sx={styles.merge(
        'base',
        isSelected && 'active',
        (option.isDisabled || option.isLoading) && 'disabled',
      )}
      onClick={handleClick}
      {...rest}
    >
      <Box mx={2} display="flex" alignItems="center" justifyContent="center">
        {iconMap[option.id]}
      </Box>
      <Box>{option.label}</Box>
    </Stack>
  );

  function handleClick(): void {
    if (!option.isDisabled) {
      onClick(option.id);
    }
  }
};

const styles = new SxStyles({
  base: {
    alignItems: 'center',
    p: (theme) => theme.spacing(1.5, 1),
    borderRadius: 2,
    cursor: 'pointer',
    boxShadow: `0px 0px 0px 1px ${SHARED_PALETTE.darkerGrey}`,
  },
  active: {
    boxShadow: (theme) => `0px 0px 0px 2px ${theme.palette.primary.main}`,
  },
  disabled: {
    boxShadow: `0px 0px 0px 1px ${SHARED_PALETTE.darkGrey}`,
    color: SHARED_PALETTE.darkGrey,
  },
});
