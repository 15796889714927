import { FC } from 'react';
import { useTranslation } from 'next-i18next';

import { APP_ROUTES } from '@lib/route/constants';
import { SxStyles } from '@lib/theme/sxTheme';
import { LinkButton } from '@ui/buttons/LinkButton';
import { DefaultCard } from '@ui/cards/default-card/DefaultCard';
import { SubscriptionAddonsContent } from './SubscriptionAddonsContent';
import { useManageSubscriptions } from '../common/useManageSubscriptions';
import { Box } from '@mui/material';
import { useSubscriptions } from '@lib/subscriptions/SubscriptionsContext';
import { replaceRouteParams } from '@lib/route/routeParams';

export const SubscriptionAddons: FC = () => {
  const { t } = useTranslation();
  const { isManageButtonVisible, isManageButtonEnabled, products } =
    useManageSubscriptions();
  const { selectedSubscription, isLoading } = useSubscriptions();
  const subscriptionHistoryRoutePath = replaceRouteParams(
    APP_ROUTES.PROFILE.SUBSCRIPTION_HISTORY.path,
    { subscriptionId: selectedSubscription?.id },
  );

  return (
    <DefaultCard
      title={t('home:auth.addons.title')}
      sx={styles.getValue('cardContainer')}
      noBorder={false}
      contentSx={styles.getValue('cardContent')}
      button={
        <Box sx={{ display: 'flex', gap: 2 }}>
          {isManageButtonVisible ? (
            <LinkButton
              href={APP_ROUTES.PROFILE.SPECIAL_OFFERS.path}
              variant="contained"
              disabled={!isManageButtonEnabled}
            >
              {t('home:auth.addons.buttons.addNew')}
            </LinkButton>
          ) : undefined}
          <LinkButton
            href={subscriptionHistoryRoutePath}
            variant="contained"
            disabled={isLoading}
          >
            {t('home:auth.addons.buttons.viewHistory')}
          </LinkButton>
        </Box>
      }
      data-testid="subscription-addons-panel"
    >
      <SubscriptionAddonsContent products={products} />
    </DefaultCard>
  );
};

const styles = new SxStyles({
  cardContainer: {
    maxHeight: {
      md: '600px',
    },
  },
  cardContent: {
    overflowY: {
      md: 'auto',
    },
  },
  action: {
    color: 'light.main',
  },
});
