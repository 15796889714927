import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import { useTranslation } from 'next-i18next';
import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

interface UseInventoryTypeVariantsConfigReturn {
  simVariantId?: string;
  esimVariantId?: string;
  isSimVariant: (variantId?: string) => boolean;
  isESimVariant: (variantId?: string) => boolean;
}

export const useInventoryTypeVariantsConfig =
  (): UseInventoryTypeVariantsConfigReturn => {
    const { inventoryTypeVariantsConfig } = useTenantProps();
    const { t } = useTranslation();
    const simVariantId = inventoryTypeVariantsConfig?.simVariantId;
    const esimVariantId = inventoryTypeVariantsConfig?.esimVariantId;

    useEffect(() => {
      if (!simVariantId || !esimVariantId) {
        toast.error(t('errors.missingInventoryTypeVariantsConfig'), {
          toastId: 'missingInventoryTypeVariantsConfig',
        });
      }
    }, [simVariantId, esimVariantId, t]);

    const isSimVariant = useCallback(
      (variantId?: string) => !!variantId && variantId === simVariantId,
      [simVariantId],
    );

    const isESimVariant = useCallback(
      (variantId?: string) => !!variantId && variantId === esimVariantId,
      [esimVariantId],
    );

    return { isSimVariant, isESimVariant, simVariantId, esimVariantId };
  };
