import { addDays, formatISO, isValid, parseISO } from 'date-fns';
import { formatInTimeZone, OptionsWithTZ, zonedTimeToUtc } from 'date-fns-tz';

export const tomorrowDate = addDays(new Date(), 1);

export const formatDateTz = (
  utcDate: string | number | Date,
  format: string = 'P',
  options?: OptionsWithTZ,
): string => {
  if (!utcDate) return '';

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // Backend sends ISO string without timezone
  // We need to convert ISO string to contain Z symbol at the end
  const date =
    typeof utcDate === 'string'
      ? parseISO(toCorrectISOString(utcDate))
      : utcDate;

  return formatInTimeZone(date, timeZone, format, options);
};

export const fromZoned = (...args: Parameters<typeof zonedTimeToUtc>) => {
  const [date] = args;
  if (!isValid(date)) return '';
  const utcDate = zonedTimeToUtc(...args);
  return utcDate.toISOString();
};

export const fromZonedLocalTz = (
  date: string | number | Date,
  options?: OptionsWithTZ,
) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return fromZoned(date, timeZone, options);
};

export const toIsoDate = (...args: Parameters<typeof formatISO>) => {
  const [date, options] = args;

  if (isValid(date)) {
    return formatISO(date, options);
  }

  return '';
};

const toCorrectISOString = (utcDate: string) =>
  utcDate.endsWith('Z') ? utcDate : `${utcDate}Z`;
