import { DeepMap, FieldError, FieldValues } from 'react-hook-form';
import { TFunction } from 'next-i18next';
import { pathOr } from 'ramda';

import { YupError } from './types';

export const getYupErrorMessage = (
  error: YupError | false,
  t: TFunction
): string => {
  if (error && error.message) {
    if (typeof error?.message !== 'string') {
      return t(error.message.key, { ...error.message.values });
    }

    if (typeof error?.message === 'string') {
      return t(error.message);
    }
  }

  return '';
};

export const getFormError = (
  name: string,
  errors: DeepMap<FieldValues, FieldError>
): YupError | false => {
  return pathOr<false | YupError>(false, name.split('.'), errors);
};
