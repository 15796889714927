import {
  InventoryItem,
  Subscription,
} from '@api/ocb-digital/subscription/types';
import { useSubscriptions } from '@lib/subscriptions/SubscriptionsContext';
import { Button } from '@ui/buttons/Button';
import { DefaultCard } from '@ui/cards/default-card/DefaultCard';
import { Table } from '@ui/table/Table';
import { TableHeadCell } from '@ui/table/types';
import { useTranslation } from 'next-i18next';
import { FC, ReactNode, useCallback, useRef, useState } from 'react';
import { SimSwapModal } from './SimSwapModal';
import { useLocalisedDate } from '@lib/time';
import { SYMBOLS } from '@lib/placeholders/constants';
import {
  ResendInventoryQRModal,
  ResendInventoryQRModalRef,
} from './ResendInventoryQRModal';
import { useInventoryTypeVariantsConfig } from '@lib/useInventoryTypeVariantsConfig';
import { ViewInventoryQRModal } from './ViewInventoryQRModal';

export const Resources: FC = () => {
  const { selectedSubscription, isLoading } = useSubscriptions();
  const { formatDateTimeTz } = useLocalisedDate();
  const { isESimVariant } = useInventoryTypeVariantsConfig();
  const { t } = useTranslation('home');
  const [isSimSwapModalOpen, setIsSimSwapModalOpen] = useState(false);
  const [inventoryItem, setInventoryItem] = useState<InventoryItem>();
  const [inventoryQrCode, setInventoryQrCode] = useState<string>();

  const resendInventoryQRModalRef = useRef<ResendInventoryQRModalRef>(null);
  const onOpenResendInventoryQRModal = useCallback(
    (inventoryItem: InventoryItem) => {
      resendInventoryQRModalRef.current?.openModal(inventoryItem);
    },
    [],
  );

  const tableHeaderCells: TableHeadCell<
    Pick<
      InventoryItem,
      'dataType' | 'serial' | 'inventoryStatus' | 'validFrom' | 'validTill'
    > & { actions: ReactNode; variant: string }
  >[] = [
    {
      id: 'dataType',
      formatLabel: () => t('auth.resources.table.header.type'),
    },
    {
      id: 'variant',
      formatLabel: () => t('auth.resources.table.header.variant'),
    },
    {
      id: 'serial',
      formatLabel: () => t('auth.resources.table.header.id'),
    },
    {
      id: 'inventoryStatus',
      formatLabel: () => t('auth.resources.table.header.status'),
      formatRowValue: (value) =>
        value?.inventoryStatus
          ? t(`auth.resources.table.inventoryStatus.${value?.inventoryStatus}`)
          : SYMBOLS.emptyText,
    },
    {
      id: 'validFrom',
      formatLabel: () => t('auth.resources.table.header.validFrom'),
      formatRowValue: (value) =>
        value?.validFrom
          ? formatDateTimeTz(value?.validFrom)
          : SYMBOLS.emptyText,
    },
    {
      id: 'validTill',
      formatLabel: () => t('auth.resources.table.header.validTill'),
      formatRowValue: (value) =>
        value?.validTill
          ? formatDateTimeTz(value?.validTill)
          : SYMBOLS.emptyText,
    },
    {
      id: 'actions',
      formatLabel: () => '',
      align: 'right',
    },
  ];

  const rows = selectedSubscription?.inventory?.map((item) => {
    const showSwapButton =
      !item.validTill &&
      item?.inventoryStatus === 'IN_USE' &&
      item?.dataType === 'SIM' &&
      !isESimVariant(item?.inventoryTypeVariant?.id);
    const showResendInventoryQRButton =
      (item?.inventoryStatus === 'ALLOCATED' ||
        item?.inventoryStatus === 'IN_USE') &&
      isESimVariant(item?.inventoryTypeVariant?.id);
    const showViewQRCodeButton =
      item?.inventoryTypeVariant?.id === 'eSIM' &&
      (item?.inventoryStatus === 'ALLOCATED' ||
        item?.inventoryStatus === 'IN_USE') &&
      item?.qrCode;
    return {
      dataType: item?.inventoryType?.name,
      variant: item?.inventoryTypeVariant?.name,
      serial: item?.serial,
      inventoryStatus: item?.inventoryStatus,
      validFrom: item?.validFrom,
      validTill: item?.validTill,
      actions: (
        <>
          {showSwapButton && (
            <Button
              size="small"
              onClick={() => onOpenSimSwapModal(item)}
              sx={{ mb: 1 }}
            >
              {t('auth.resources.table.actions.simSwap')}
            </Button>
          )}
          {showResendInventoryQRButton && (
            <Button
              size="small"
              onClick={() => onOpenResendInventoryQRModal(item)}
              sx={{ ml: 1, mb: 1 }}
            >
              {t('auth.resources.table.actions.resendInventoryQRCode')}
            </Button>
          )}
          {showViewQRCodeButton && (
            <Button
              size="small"
              onClick={() => setInventoryQrCode(item?.qrCode)}
              sx={{ ml: 1, mb: 1 }}
            >
              {t('common:view-inventory-qr-modal.view-qr-code')}
            </Button>
          )}
        </>
      ),
    };
  });

  return (
    <DefaultCard
      title={t('auth.resources.title')}
      noBorder={false}
      data-testid="resources-panel"
    >
      <Table
        rows={rows ?? []}
        headCells={tableHeaderCells}
        orderBy="validFrom"
        order="desc"
        isLoading={isLoading}
      />
      <SimSwapModal
        isOpen={isSimSwapModalOpen}
        onClose={onCloseSimSwapModal}
        subscription={selectedSubscription ?? ({} as Subscription)}
        inventoryItem={inventoryItem}
      />
      <ViewInventoryQRModal
        open={!!inventoryQrCode}
        onClose={() => setInventoryQrCode(undefined)}
        qrCode={inventoryQrCode}
      />
      <ResendInventoryQRModal ref={resendInventoryQRModalRef} />
    </DefaultCard>
  );

  function onOpenSimSwapModal(inventoryItem: InventoryItem) {
    setInventoryItem(inventoryItem);
    setIsSimSwapModalOpen(true);
  }

  function onCloseSimSwapModal() {
    setIsSimSwapModalOpen(false);
  }
};
